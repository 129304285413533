import { App, defineAsyncComponent } from 'vue'

const UniversalText = defineAsyncComponent(() => import('./404.vue'))

const register = (app: App<Element>): void => {
  app
    .component('UniversalText', UniversalText)
  }

export default {
  register,
}
