<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3769_17300)">
<path d="M16.5 9H13.5L11.25 15.75L6.75 2.25L4.5 9H1.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_3769_17300">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
