const getIntl = (val: number) => Intl.NumberFormat('ru-RU', {
  style: 'decimal',
}).format(val)

interface MaskaOpt {
  preProcess: (val: string) => string,
  postProcess: (val: string) => string|undefined,
}

export const options: MaskaOpt = {
  preProcess: (val: string) => val.replace(/\s+/g, ''),
  postProcess: (val: string) => {
    if (!val) return ''
    if (+val) {
    return getIntl(+val)
    } else if (val.includes(',')) {
      const n = val.split(',')[0]
      const d = val.split(',')[1]
      const res = getIntl(+n)
      return `${res},${d}`
    }
  }
}
