import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { createRoutes as createOrderingVolumesRoutes } from '@components/Profiles/Business/OrderingVolumes/router'

const NotFoundView = () => import('@components/Profiles/Common/NotFound/404.vue')

export function createRoutes(options:optionsT): any {
  return [
    ...createOrderingVolumesRoutes(options),
    useRoute('/:pathMatch(.*)*', NotFoundView, '404'),
  ]
}
