<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6665 2.66602H11.9998C12.3535 2.66602 12.6926 2.80649 12.9426 3.05654C13.1927 3.30659 13.3332 3.64573 13.3332 3.99935V13.3327C13.3332 13.6863 13.1927 14.0254 12.9426 14.2755C12.6926 14.5255 12.3535 14.666 11.9998 14.666H3.99984C3.64622 14.666 3.30708 14.5255 3.05703 14.2755C2.80698 14.0254 2.6665 13.6863 2.6665 13.3327V3.99935C2.6665 3.64573 2.80698 3.30659 3.05703 3.05654C3.30708 2.80649 3.64622 2.66602 3.99984 2.66602H5.33317" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.0002 1.33398H6.00016C5.63197 1.33398 5.3335 1.63246 5.3335 2.00065V3.33398C5.3335 3.70217 5.63197 4.00065 6.00016 4.00065H10.0002C10.3684 4.00065 10.6668 3.70217 10.6668 3.33398V2.00065C10.6668 1.63246 10.3684 1.33398 10.0002 1.33398Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 7.33398H10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 10.666H10.6667" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.3335 7.33398H5.34016" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.3335 10.666H5.34016" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
