import { STAFF_DOCUMENTS } from '@/router/Staff/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DOCS_DEAL } from '@md/users/index'

const Documents = () => import('@components/Profiles/Staff/Documents/View.vue')

export const STAFF_DOCUMENTS_ROUTE = 'staff-documents'
const {
  VIEW_ADMIN,
} = DOCS_DEAL

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(STAFF_DOCUMENTS, Documents, STAFF_DOCUMENTS_ROUTE, {
      props: true,
      meta: {
        title: $t('Документи угод'),
        ...accessPermission(VIEW_ADMIN),
      },
    }),
  ]
}
