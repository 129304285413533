import { SELLER_BARGAINING_CATALOG } from '@/router/Seller/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { BARGAIN } from '@md/users/index'

const BargainingCatalog = () => import('@components/Profiles/Seller/Bargaining/View.vue')

export const SELLER_BARGAINING_ROUTE = 'seller-bargaining'
const {
  VIEW_SELLER,
} = BARGAIN

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(SELLER_BARGAINING_CATALOG, BargainingCatalog, SELLER_BARGAINING_ROUTE, {
      props: true,
      meta: {
        title: $t('Торги'),
        ...accessPermission(VIEW_SELLER),
      },
    }),
  ]
}
