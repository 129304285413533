import {
  SELLER_OFFERS_CATALOG,
  SELLER_DETAIL_OFFER,
} from '@/router/Seller/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { OFFERS } from '@md/users/index'

const BusinessOffersCatalog = () => import('@components/Profiles/Seller/Offers/View.vue')
const DetailOffersView = () => import('@components/Profiles/Trader/Offers/Views/Create/View.vue')

export const SELLER_OFFERS_ROUTE = 'seller-offers'

const p = (name: string) => SELLER_OFFERS_ROUTE + name

export const SELLER_OFFER_DETAIL = p(':detail')

const {
  VIEW_SELLER,
} = OFFERS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(SELLER_OFFERS_CATALOG, BusinessOffersCatalog, SELLER_OFFERS_ROUTE, {
      props: true,
      meta: {
        title: $t('Пропозиції продажу газу'),
        ...accessPermission(VIEW_SELLER),
      },
    }),
    useRoute(SELLER_DETAIL_OFFER, DetailOffersView, SELLER_OFFER_DETAIL, {
      props: true,
      meta: {
        title: $t('Деталі пропозиції'),
        ...accessPermission(VIEW_SELLER),
      },
    }),
  ]
}
