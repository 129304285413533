export const offerStatus = {
  AVAILABLE: '00100-available',
  ORDER: '00300-by-order',
  BY_BARGAIN: '00400-by-bargain',
  PREPARATION: '00500-deal-preparation',
  DEAL: '00600-deal',
  ACTIVE_BARGAIN: '00700-active-bargain',
  ARCHIVE: '00900-archive',
  UPDATED: '01100-updated',
}

export const historyTypes = {
  CREATED: '00100-created',
  SELLING: '00200-selling',
  CHANGED: '00300-changed',
  ARCHIVED: '00400-archived',
  BARGAIN: '00500-bargain',
}
