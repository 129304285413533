import { OWNED_OFFERS_CATALOG } from '@/router/Trader/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { OFFERS } from '@md/users/index'

const OwnedOffersCatalog = () => import('@components/Profiles/Trader/Offers/Views/OwnedOffers/View.vue')

export const OWNED_OFFERS_ROUTE = 'owned'

const {
  CHANGE,
} = OFFERS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(OWNED_OFFERS_CATALOG, OwnedOffersCatalog, OWNED_OFFERS_ROUTE, {
      props: true,
      meta: {
        title: $t('Мої пропозиції продажу газу'),
        ...accessPermission(CHANGE),
      },
    }),
  ]
}
