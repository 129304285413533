/* eslint-disable no-underscore-dangle */
import Centrifuge from 'centrifuge';
import Message from './message';

export default class Instant {
  private csrfToken = '';
  private channels: Set<string> = new Set();
  private _onMessage: (message: Message) => void = (message) =>
    console.log(JSON.stringify(message, null, "  "));
  private backendUri: string;
  private websocketsUri: string;
  private activeCompany?: any;
  private verbose: boolean;
  private tempMsg?: Message;
  private client: Centrifuge;

  constructor(
    backendUri: string,
    websocketsUri: string,
    verbose = false,
    activeCompany?: {
      id: number,
      permissions?: string[],
    }
  ) {
    this.backendUri = backendUri;
    this.websocketsUri = websocketsUri;
    this.activeCompany = activeCompany;
    this.verbose = verbose;
    this.client = new Centrifuge(
      `${this.websocketsUri}connection/websocket`,
      {
        subscribeEndpoint: `${this.backendUri}company/subscribe/${
          this.activeCompany?.id ?? ''
        }/`,
        refreshEndpoint: `/centrifuge/refresh/${
          this.activeCompany?.id ?? ''
        }/`,
        debug: false,
      }
    );
  }

  set onMessage(func: (message: Message) => void) {
    this._onMessage = func;
  }

  async connect(autosub = true): Promise<void> {
    if (autosub) {
      this.subscribeToAllChannels();
    }
    let end: (value?: boolean | PromiseLike<boolean> | undefined) => void;
    const connected = new Promise<boolean>((resolve) => {
      end = (value?: boolean | PromiseLike<boolean> | undefined) => {
        if (value !== undefined) {
          resolve(value);
        }
      };
    });
    this.client.on('connect', () => {
      end(true);
    });
    this.client.connect();
    await connected;
  }

  private subscribeToAllChannels() {
    this.channels.forEach((c) => {
      console.log(JSON.stringify(`channel: ${c}`));
      this.client.subscribe(c, (message) => {
        const msg = this.processRawMessage(message.data);
        this._onMessage(msg);
      });
    });
  }

  public async getToken(): Promise<void> {
    const opts = this.postHeader({});
    const uri = `${this.backendUri}company/get_token/${
      this.activeCompany?.id ?? ''
    }/`;
    const response = await fetch(uri, opts);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const data = await response.json();
    this.processResponse(data);
  }

  private postHeader(payload: any) {
    const header: any = {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify(payload),
    };
    header.headers = {
      'Content-Type': 'application/json',
    };
    if (this.csrfToken !== '') {
      header.headers = {
        'Content-Type': 'application/json',
        'X-CSRFToken': this.csrfToken,
      };
    }
    return header;
  }

  private processResponse(data: any) {
    this.csrfToken = data.csrf_token;
    this.client.setToken(data.ws_token);
    data.channels.forEach((c: string) => {
      this.channels.add(c);
    });
  }

  private processRawMessage(message: {
    channel: string;
    site: string;
    message?: string;
    data?: any;
    event_class?: string;
  }): Message {
    this.tempMsg = undefined;
    try {
      this.tempMsg = new Message(message);
    } catch (e) {
      throw new Error(`Can not process message ${message} ${e}`);
    }
    return this.tempMsg;
  }
}
