<template>
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3615 25.3258H17.269C23.0252 25.3258 25.3277 23.0233 25.3277 17.267V10.3595C25.3277 4.60328 23.0252 2.30078 17.269 2.30078H10.3615C4.60523 2.30078 2.30273 4.60328 2.30273 10.3595V17.267C2.30273 23.0233 4.60523 25.3258 10.3615 25.3258Z" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.7222 8.92359V16.6945C20.7222 15.4282 19.6861 14.392 18.4197 14.392H9.20973C7.94335 14.392 6.90723 15.4282 6.90723 16.6945V8.92359C6.90723 7.65722 7.94335 6.62109 9.20973 6.62109H18.4197C19.6861 6.62109 20.7222 7.65722 20.7222 8.92359Z" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.8729 18.1328H20.7217" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.90711 18.1328H5.75586" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.7222 16.1156V12.6619C20.7222 11.3955 19.6861 10.3594 18.4197 10.3594H9.20973C7.94335 10.3594 6.90723 11.3955 6.90723 12.6619V16.1156" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.7222 16.6931V18.1322H16.6929C16.6929 19.7209 15.4035 21.0103 13.8147 21.0103C12.226 21.0103 10.9366 19.7209 10.9366 18.1322H6.90723V16.6931C6.90723 15.4267 7.94335 14.3906 9.20973 14.3906H18.4197C19.6861 14.3906 20.7222 15.4267 20.7222 16.6931Z" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
