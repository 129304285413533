import {
  join, evolve, map, pipe, filter, not,
} from 'ramda';
import { isEmpty } from '@aspectus/vue-utils';
import {
  keyedTransformer as k,
  sameTransformer,
  composeTransformers,
} from './utils';
import { parametersMiddleware } from '@aspectus/resource';
import { set } from 'date-fns';

const checkEmpty = value => (isEmpty(value) ? undefined : value);
const checkUrl = value => (value && value.startsWith('data') ? value : undefined);
const ar = value => (Array.isArray(value) ? value : [value]);
// const selectTo = curry((filter, id) => filter.props.items.find(({ id }) => id === id));
const selectFrom = ({ id }) => id;
const multiselectFrom = pipe(ar, map(selectFrom), filter(pipe(isEmpty, not)));
const multisameFrom = pipe(ar, map(sameTransformer), filter(pipe(isEmpty, not)));
const fileFrom = pipe(ar, map(selectFrom), join(' '));
const filesFrom = pipe(ar, map(selectFrom));
export const customDateFrom = date => {
  const currMonth = date.month + 1
  return `${date.year}-${date.month < 9 ? "0" + currMonth : currMonth}-01`
};

const dateFrom = date => new Date(date).toISOString();
const minTimeDateFrom = date => {
  const currDate = set(new Date(date), {
    hours: 0,
    minutes: 1,
  })
  return new Date(currDate).toISOString()
};
const maxTimeDateFrom = date => {
  const currDate = set(new Date(date), {
    hours: 23,
    minutes: 59,
  })
  return new Date(currDate).toISOString()
};
const dateOnlyFrom = date => {
  const dateTransform = new Date(date);
  return `${dateTransform.getFullYear()}-${dateTransform.getMonth() + 1}-${dateTransform.getDate()}`;
};
const dateMonthFrom = date => (date === 'None' ? null : `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-02T00:00:00`);
const customDateMonthFrom = date => (date === 'None' ? null : `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}-01`);
const dateRangeFrom = evolve({ min: minTimeDateFrom, max: maxTimeDateFrom });
const dateOnlyRangeFrom = evolve({ min: dateOnlyFrom, max: dateOnlyFrom });
const combinedInputFrom = map(composeTransformers(
  k(selectFrom, 'element', 'id'),
  k(sameTransformer, 'search')
));

export const simplifiers = {
  photo: key => k(pipe(sameTransformer, checkEmpty, checkUrl), key),
  file: key => k(fileFrom, key),
  files: key => k(filesFrom, key),
  sameEmpty: key => k(pipe(sameTransformer), key),
  same: key => k(pipe(sameTransformer, checkEmpty), key),
  multisame: key => k(pipe(multisameFrom, checkEmpty), key),
  search: key => k(pipe(sameTransformer, checkEmpty), key),
  select: key => k(pipe(selectFrom, checkEmpty), key),
  multiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  range: key => k(pipe(sameTransformer, checkEmpty), key),
  date: key => k(pipe(dateFrom, checkEmpty), key),
  customDate: key => k(pipe(customDateFrom, checkEmpty), key),
  customDateMonth: key => k(pipe(customDateMonthFrom, checkEmpty), key),
  dateMonth: key => k(pipe(dateMonthFrom, checkEmpty), key),
  dateRange: key => k(pipe(dateRangeFrom, checkEmpty), key),
  onlyDateRange: key => k(pipe(dateOnlyRangeFrom, checkEmpty), key),
  autocompleteMultiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  combinedInput: key => k(pipe(combinedInputFrom, checkEmpty), key),
};

export const filterAPIMiddleware = transformer => parametersMiddleware(parameters => ({
  ...parameters,
  filters: JSON.stringify(transformer(parameters.filters)),
}));
