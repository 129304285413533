import { prefixLanguage } from '@sub/src/utils/urls'
import { BASE_URL } from '@/router/consts'

const p = (postfix: string) => prefixLanguage(`${BASE_URL}${postfix}/`)
export const OFFERS_CATALOG = p(':id?/offers')
export const SETTINGS = p('settings')
export const MY_COMPANIES = p('companies')
export const COMPANY = p('company/:id')
export const NOTIFICATIONS = p('notifications')
export const WHAT_NEW = p('what-new')

// COMPANY VERIFICATION STEPS
export const VERIFICATION = p('verification')
export const STEP_ONE = p('verification/one/:id?')
export const STEP_TWO = p('verification/two/:id?')
export const STEP_THREE = p('verification/three/:id?')
export const STEP_FOUR = p('verification/four/:id?')
export const STEP_FIVE = p('verification/five/:id?')

// ORDERS
export const OWNED_ORDERS_CATALOG = p(':id/orders/owned')

export const CREATE_ORDER = p(':id/orders/create')
export const DETAIL_ORDER = p(':id/orders/detail/:order')
export const UPDATE_ORDER = p(':id/orders/update/:order')

// DEAL
export const DEALS_CATALOG = p(':id/deals/owned')

export const DEAL = p(':id/deal')
export const DEAL_STEP_ONE = p(':id/deal/one/:product/:tolerance/:offerId')
export const DEAL_STEP_TWO = p(':id/deal/two/:product/:tolerance/:offerId')
export const DEAL_STEP_THREE = p(':id/deal/three/:product/:tolerance/:offerId')

export const DEAL_DETAILS = p(':id/deal/details/:deal')

// DOCUMENTS
export const DOCUMENTS_CATALOG = p(':id/documents')
export const DOCUMENTS_DETAIL = p(':id/documents/detail/:docId')
export const CONTRACT_DETAIL = p(':id/documents/contract/detail/:docId')

//  BARGAINING
export const BARGAINING_CATALOG = p(':id/bargaining')

// PAYMENTS
export const AFTER_PAYMENT = p(':id/after-payment/:docId')
