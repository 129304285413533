import {
  createSender,
  createReceiver,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'notifications'

// GET FLAGS LIST
const GET_FLAGS = prefixAPI('/flags/list/', MODEL)

export const getFlagsList = createReceiver(GET_FLAGS)

const MODEL_NOTIFICATIONS = `${MODEL}/notifications`

// GET NOTIFICATIONS LIST
const GET_NOTIFICATIONS = prefixAPI('/list/{?flags,category,company,limit,offset}', MODEL_NOTIFICATIONS)

export const getNotificationsList = createReceiver(GET_NOTIFICATIONS)

// GET STATS
const GET_STATS = prefixAPI('/stats/', MODEL_NOTIFICATIONS)

export const getStats = createReceiver(GET_STATS)

// CHANGE FLAGS
const CHANGE_FLAGS = prefixAPI('/change-flags/{?ids}', MODEL_NOTIFICATIONS)

export const changeFlagsResource = createSender(CHANGE_FLAGS)
