import { DOCUMENTS_CATALOG } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DOCS_DEAL } from '@md/users/index'

const DocumentsCatalog = () => import('@components/Profiles/Common/Documents/Catalog/View.vue')

export const DOCUMENTS_ROUTE = 'documents'
const {
  VIEW,
} = DOCS_DEAL

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(DOCUMENTS_CATALOG, DocumentsCatalog, DOCUMENTS_ROUTE, {
      props: true,
      meta: {
        title: $t('Документи'),
        ...accessPermission(VIEW),
      },
    }),
  ]
}
