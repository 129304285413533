import { WHAT_NEW } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { BID } from '@md/users/index'

const WhatNewView = () => import('@components/Profiles/Common/WhatNew/View.vue')

export const WHAT_NEW_ROUTE = 'what-new'

const {
  VIEW,
} = BID

export function createRoutes(options: optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(WHAT_NEW, WhatNewView, WHAT_NEW_ROUTE, {
      meta: {
        title: $t('Що нового'),
        ...accessPermission(VIEW),
      },
    }),
  ]
}
