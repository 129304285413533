export default class Message {
  channelName: string;
  site: string;
  msg: string;
  data: any;
  eventClass: string;
  date: Date;

  constructor(message: {
    channel: string;
    site: string;
    message?: string;
    data?: any;
    event_class?: string;
  }) {
    this.channelName = message.channel;
    this.site = message.site;
    this.msg = message.message || '';
    this.data = message.data || {};
    this.eventClass = message.event_class || '';
    this.date = new Date();
  }
}
