<template>
<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1575_18940)">
<path d="M11.5938 1.62891H7.09375C6.67954 1.62891 6.34375 1.96469 6.34375 2.37891V3.87891C6.34375 4.29312 6.67954 4.62891 7.09375 4.62891H11.5938C12.008 4.62891 12.3438 4.29312 12.3438 3.87891V2.37891C12.3438 1.96469 12.008 1.62891 11.5938 1.62891Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.15875 9.58633C8.30501 9.44007 8.47865 9.32405 8.66974 9.2449C8.86084 9.16574 9.06566 9.125 9.2725 9.125C9.47934 9.125 9.68416 9.16574 9.87526 9.2449C10.0664 9.32405 10.24 9.44007 10.3863 9.58633C10.5325 9.73259 10.6485 9.90623 10.7277 10.0973C10.8068 10.2884 10.8476 10.4932 10.8476 10.7001C10.8476 10.9069 10.8068 11.1117 10.7277 11.3028C10.6485 11.4939 10.5325 11.6676 10.3863 11.8138L6.30625 15.8788L3.34375 16.6288L4.08625 13.6663L8.15875 9.58633Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3438 3.12891H13.8438C14.2416 3.12891 14.6231 3.28694 14.9044 3.56825C15.1857 3.84955 15.3438 4.23108 15.3438 4.62891V15.1289C15.3438 15.5267 15.1857 15.9083 14.9044 16.1896C14.6231 16.4709 14.2416 16.6289 13.8438 16.6289H9.71875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.34375 10.2539V4.62891C3.34375 4.23108 3.50179 3.84955 3.78309 3.56825C4.06439 3.28694 4.44593 3.12891 4.84375 3.12891H6.34375" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1575_18940">
<rect width="18" height="18" fill="white" transform="translate(0.34375 0.128906)"/>
</clipPath>
</defs>
</svg>
</template>
