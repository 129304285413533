import {
  createReceiver,
  createSender,
  createUpdater,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const MODEL = 'profile'

// GET USER INFO
const GET_USER = prefixAPI('/retrieve/', MODEL)

export const userRetrive = createReceiver(GET_USER)

// UPDATE USER INFO
const USER_UPDATE_LINK = prefixAPI('/update/', MODEL)
export const userUpdate = createUpdater(USER_UPDATE_LINK)

// TURN 2F
const TURN_2F_LINK = prefixAPI('/turn-2f/', MODEL)

export const turn2fResource = createSender(TURN_2F_LINK)

// TURN UNSUBSCRIBE
const TURN_UNSIBSCRIBE_LINK = prefixAPI('/turn-unsubscribe/', MODEL)

export const turnUnsibscribeResource = createSender(TURN_UNSIBSCRIBE_LINK)
