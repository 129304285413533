import { DEALS_CATALOG } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DEALS } from '@md/users/index'

const DealsCatalog = () => import('@components/Profiles/Common/Deals/Catalog/View.vue')

export const DEALS_ROUTE = 'owned-deals'
const {
  VIEW,
} = DEALS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(DEALS_CATALOG, DealsCatalog, DEALS_ROUTE, {
      props: true,
      meta: {
        title: $t('Мої угоди'),
        ...accessPermission(VIEW),
      },
    }),
  ]
}
