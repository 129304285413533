import { UserInfo } from '@md/users/types'

export class UserBase {
  determined = false;

  info: UserInfo = {
    basePermissions: [],
    companies: [],
    counters: {},
    currentCompany: undefined,
    email: '',
    firstName: '',
    id: 0,
    is2f: false,
    lastName: '',
    phoneNumber: '',
  };

  isAuthenticated(): string | number | boolean {
    return this.determined && this.info && (this.info.id || this.info.email) || false;
  }
}

export class User extends UserBase {
  constructor(info: UserInfo, determined = true) {
    super();

    this.determined = determined;
    this.info = info || this.info;
  }
}
