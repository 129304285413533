type Slot = string

export const normalizeSelectValue = <T = any>(value: T | T[] | null | undefined): T[] => (
  Array.isArray(value) || typeof value === 'undefined' || value === null
  ? (value || [])
  : [value]
  ) as unknown as T[]
  
export function sameTransformer(data: unknown): unknown {
  return data
}

export function composeTransformers(...transformers: any[]): (source: any) => any {
  const prepared = transformers.reverse();

  return function (source) {
    return prepared.reduce((acc, transform) => transform(source, acc), {});
  };
}

/* eslint-disable */
export function keyedTransformer(
  transformer: any, from: any, to = from, transformOnUndefined = false, dflt: any
): any {
  return function (source: any, result: any) {
    const data = (source && typeof source[from] !== 'undefined' && source[from] !== null)
      ? source[from]
      : dflt;

    if (!transformOnUndefined && typeof data === 'undefined') {
      return result;
    }

    return {
      ...result,
      [to]: transformer(data),
    };
  };
}

export const eSlotsResolver = (acc: Record<string, string>, key: Slot ): Record<string, string> => {
  acc[key] = key
  return acc
}

export const getESlots = (keys: Slot[]) => keys
  .reduce(eSlotsResolver, {})
