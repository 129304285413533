import { BASE_URL } from '@/router/consts'
import { createRoutes as CommonRoutes } from '@/router/Common/routes'
import { createRoutes as TraderRoutes } from '@/router/Trader/routes'
import { createRoutes as BusinessRoutes } from '@/router/Business/routes'
import { createRoutes as StaffRoutes } from '@/router/Staff/routes'
import { createRoutes as DispatcherRoutes } from '@/router/Dispatcher/routes'
import { createRoutes as SellerRoutes } from '@/router/Seller/routes'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'
import { getInitialRoute } from '@/modules/users/permissions'
const ViewComponent = () => import('@components/Profiles/Views/Profile.vue')

export default function createInitialRoutes(options:optionsT): any {
  return [
    useRoute(BASE_URL, ViewComponent, 'profile', {
      redirect: { name: getInitialRoute() },
      children: [
        ...CommonRoutes(options),
        ...TraderRoutes(options),
        ...BusinessRoutes(options),
        ...StaffRoutes(options),
        ...DispatcherRoutes(options),
        ...SellerRoutes(options),
      ],
    }),
  ]
}
