import { accessGuard } from '@aspectus/vue-router-permissions'
import createInitialRoutes from '@/router/routes'

export type optionsT = { app: any, createInstance: any, createWebHistory: any, pinia: any }

export function createRoutes(options:optionsT): any[] {
  return [
    ...createInitialRoutes(options),
  ];
}

export function scrollBehavior(to: Record<string, unknown>, savedPosition: boolean): any {
  if (savedPosition) {
    return savedPosition;
  }

  return {
    top: window.scrollX,
    left: window.scrollY,
  };
}

export default function createRouter(options:optionsT): any {
  const { createInstance, createWebHistory } = options;
  const router = createInstance({
    history: createWebHistory(),
    scrollBehavior,
    routes: createRoutes(options),
  });

  // CHECK ACCESS TO ROUTE PATH
  router.beforeEach(accessGuard(options));

  return router;
}
