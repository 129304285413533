<template>
<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_885_1070)">
<path d="M14.4795 6.03149C15.7221 6.03149 16.7295 5.02413 16.7295 3.78149C16.7295 2.53885 15.7221 1.53149 14.4795 1.53149C13.2369 1.53149 12.2295 2.53885 12.2295 3.78149C12.2295 5.02413 13.2369 6.03149 14.4795 6.03149Z" stroke="#56D0E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.47949 11.2815C6.72213 11.2815 7.72949 10.2741 7.72949 9.03149C7.72949 7.78885 6.72213 6.78149 5.47949 6.78149C4.23685 6.78149 3.22949 7.78885 3.22949 9.03149C3.22949 10.2741 4.23685 11.2815 5.47949 11.2815Z" stroke="#56D0E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.4795 16.5315C15.7221 16.5315 16.7295 15.5241 16.7295 14.2815C16.7295 13.0389 15.7221 12.0315 14.4795 12.0315C13.2369 12.0315 12.2295 13.0389 12.2295 14.2815C12.2295 15.5241 13.2369 16.5315 14.4795 16.5315Z" stroke="#56D0E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.42188 10.1641L12.5444 13.1491" stroke="#56D0E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5369 4.91406L7.42188 7.89906" stroke="#56D0E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_885_1070">
<rect width="18" height="18" fill="white" transform="translate(0.979492 0.0314941)"/>
</clipPath>
</defs>
</svg>
</template>
