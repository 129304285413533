import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { createRoutes as createStaffDocumentsRoutes } from '@components/Profiles/Staff/Documents/router'
import { createRoutes as createStaffBsnsActsRoutes } from '@components/Profiles/Staff/BsnsActs/router'

const NotFoundView = () => import('@components/Profiles/Common/NotFound/404.vue')

export function createRoutes(options:optionsT): any {
  return [
    ...createStaffDocumentsRoutes(options),
    ...createStaffBsnsActsRoutes(options),
    useRoute('/:pathMatch(.*)*', NotFoundView, '404'),
  ]
}
