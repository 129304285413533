import {
  UserPermissions,
  UserCompany,
  PermissionType,
} from '@md/users/types'

export const { userPermissions }: UserPermissions  = window.USER
export const userCompanies: UserCompany[] = JSON.parse(window.ALL_USER_COMPANIES || '[]')
export const allPermissions: PermissionType = JSON.parse(window.ALL_PERMISSIONS || '[]')

export const {
  POINT: { POINT },
  OFFERS: { OFFERS },
  ORDERS: { ORDERS },
  BID: { BID },
  COMPANY: { COMPANY, DOCS_CONTRACT_ADMIN, DISPATCHER_TABLE },
  DEALS: { DEALS, DOCS_DEAL, DEAL_POINT_SUM },
  BARGAIN: { BARGAIN },
  USER: { USER },
} = allPermissions
