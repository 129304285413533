import { DirectiveBinding } from 'vue'

export const access = {
  beforeMount: (el: HTMLElement, binding: DirectiveBinding<any>): any => {
    el.addEventListener('keypress', (e: any) => {
      const string = String.fromCharCode(e.which)
      const regex = new RegExp(binding.value)
      if (!string.match(regex)) {
        e.preventDefault()
      }
    })
  }
}
