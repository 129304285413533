<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 14H3V21H10V14Z" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10 3H3V10H10V3Z" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 4H21" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 9H21" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 15H21" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 20H21" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
