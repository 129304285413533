import { SELLER_USERS } from '@/router/Seller/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { USER } from '@md/users/index'

const UsersCatalog = () => import('@components/Profiles/Seller/Users/View.vue')

export const SELLER_USERS_ROUTE = 'seller-users'

const {
  VIEW_SELLER,
} = USER

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(SELLER_USERS, UsersCatalog, SELLER_USERS_ROUTE, {
      props: true,
      meta: {
        title: $t('Користувачи'),
        ...accessPermission(VIEW_SELLER),
      },
    }),
  ]
}
