import { App } from 'vue'

import UI from './UI'
import Common from './Common'
import Main from './Main'
import Charts from './Charts'
import Blog from './Blog'
import Faq from './Faq'

const install = (app: App<Element>): void => {
  UI.register(app)
  Main.register(app)
  Charts.register(app)
  Blog.register(app)
  Faq.register(app)
  Common.register(app)
}

export default {
  install,
}
