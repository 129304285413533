import { path, curry } from 'ramda'
import { UserCheckerPermission, checkAuth } from '@md/auth/permissions'
import { Or } from '@aspectus/permissions'
import { accessMeta } from '@aspectus/vue-router-permissions'
import { POINT, userPermissions } from '@md/users/index'
import { STAFF_DOCUMENTS_ROUTE } from '@components/Profiles/Staff/Documents/router'
import { COMPANIES_ROUTE } from '@components/Profiles/Common/MyCompanies/router'
import { DISPATCHER_SUM_TABLE_ROUTE } from '@components/Profiles/Dispatcher/SumTable/router'
import { SELLER_DEALS_ROUTE } from '@components/Profiles/Seller/Deals/router'

const { ZPOINT_ACCESS } = POINT

const userStateGetter = path(['state', 'value', 'user'])

export function checkUser(checker: UserCheckerPermission, config = {}, ...args: any[]): UserCheckerPermission {
  return checkAuth(
    checker, Object.assign({ getter: userStateGetter }, config), ...args
  )
}

export const isPermitted = curry(
  (permission: string, user: any) => !!userPermissions.find((e: string) => e == permission)
);

export const toHasSimplePermission = curry(
  (permission: string) => !!userPermissions.find((e: string) => e == permission)
  );
  
export const getInitialRoute = (): string => {
  if (window.isSeller) return SELLER_DEALS_ROUTE
  if (window.isDispatcher) return DISPATCHER_SUM_TABLE_ROUTE
  if (window.isStaff) return STAFF_DOCUMENTS_ROUTE
  return COMPANIES_ROUTE
}

export const toBeTrader = !toHasSimplePermission(ZPOINT_ACCESS)
export const toBeBusiness: boolean = toHasSimplePermission(ZPOINT_ACCESS)

export const toHasOwnPermission = (permission: string): UserCheckerPermission => checkUser(isPermitted(permission))

export const toHasPermission = (permission: string): void => new Or(
  toHasOwnPermission(permission)
);

export const accessPermission = (permissions: string, options?: unknown|undefined): { accessConfig: any } => {
  return accessMeta(
    toHasPermission(permissions), { name: getInitialRoute() }, options
  );
}
