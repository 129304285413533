import { DISPATCHER_SUM_TABLE } from '@/router/Dispatcher/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DISPATCHER_TABLE } from '@md/users/index'

const SumTable = () => import('@components/Profiles/Dispatcher/SumTable/View.vue')

export const DISPATCHER_SUM_TABLE_ROUTE = 'dispatcher-sum-table'
const {
  VIEW,
} = DISPATCHER_TABLE

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(DISPATCHER_SUM_TABLE, SumTable, DISPATCHER_SUM_TABLE_ROUTE, {
      props: true,
      meta: {
        title: $t('Диспетчеризація'),
        ...accessPermission(VIEW),
      },
    }),
  ]
}
