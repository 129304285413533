<template>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1575_19738)">
<path d="M8.93815 14.6673C12.62 14.6673 15.6048 11.6825 15.6048 8.00065C15.6048 4.31875 12.62 1.33398 8.93815 1.33398C5.25625 1.33398 2.27148 4.31875 2.27148 8.00065C2.27148 11.6825 5.25625 14.6673 8.93815 14.6673Z" stroke="#DA224A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.9375 6L6.9375 10" stroke="#DA224A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.9375 6L10.9375 10" stroke="#DA224A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1575_19738">
<rect width="16" height="16" fill="white" transform="translate(0.9375)"/>
</clipPath>
</defs>
</svg>
</template>
