import { CREATE_ORDER, DETAIL_ORDER, UPDATE_ORDER } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { ORDERS } from '@md/users/index'

const CreateOrdersView = () => import('./View.vue')

const ROUTE_NAME = 'orders'

const p = (name: string) => ROUTE_NAME + name

export const CREATE = p(':create')
export const DETAIL = p(':detail')
export const UPDATE = p(':update')

const {
  CHANGE,
} = ORDERS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(CREATE_ORDER, CreateOrdersView, CREATE, {
      props: true,
      meta: {
        title: $t('Додавання заявки на купівлю газу'),
        ...accessPermission(CHANGE),
      },
    }),
    useRoute(DETAIL_ORDER, CreateOrdersView, DETAIL, {
      props: true,
      meta: {
        title: $t('Деталі заявки'),
        ...accessPermission(CHANGE),
      },
    }),
    useRoute(UPDATE_ORDER, CreateOrdersView, UPDATE, {
      props: true,
      meta: {
        title: $t('Редагування заявки'),
        ...accessPermission(CHANGE),
      },
    }),
  ]
}
