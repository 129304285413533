import { App } from 'vue'
import * as Sentry from '@sentry/vue'

import {
  GAS_PROD_URL,
  GAS_TEST_URL,
} from '@/const'

const SENTRY_DSN = {
  [GAS_PROD_URL]: 'https://f1c43fc18c0645a75456734907f37107@sentry.webcase-dev.com/13',
  // [GAS_TEST_URL]: 'https://f1c43fc18c0645a75456734907f37107@sentry.webcase-dev.com/13',
} as Record<string, string>

export default function sentryInit(app: App): void {
  const dsn = SENTRY_DSN[window.location.hostname]
  if (!dsn) return

  Sentry.init({
    app,
    dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  })
}
