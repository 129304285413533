import Instant from '@utils/centrifugo/base';

const instant = new Instant(
  '/cent/',
  window.CENTRIFUGO_URL || 'ws://localhost:8001/', // Centrifugo server's address
  true,
  window.USER.activeCompany
);

export default instant;
