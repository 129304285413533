import { App } from 'vue'
import { tableWrapper } from './table'
import { access } from './access'
import { vMaska } from 'maska'

const registerDirective = (app: App<Element>): void => {
  app
    .directive('table-wrapper', tableWrapper)
    .directive('access', access)
    .directive('maska', vMaska)
}

export default {
  registerDirective,
}
