import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { createRoutes as createOffersCreateRoutes } from '@components/Profiles/Trader/Offers/Views/Create/router'
import { createRoutes as createOwnedOffersCreateRoutes } from '@components/Profiles/Trader/Offers/Views/OwnedOffers/router'
import { createRoutes as createOrdersCreateRoutes } from '@components/Profiles/Trader/PurchaseOrders/router'

const NotFoundView = () => import('@components/Profiles/Common/NotFound/404.vue')

export function createRoutes(options:optionsT): any {
  return [
    ...createOffersCreateRoutes(options),
    ...createOwnedOffersCreateRoutes(options),
    ...createOrdersCreateRoutes(options),
    useRoute('/:pathMatch(.*)*', NotFoundView, '404'),
  ]
}
