import { NOTIFICATIONS } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { BID } from '@md/users/index'

const NotificationsView = () => import('@components/Profiles/Common/Notifications/View.vue')

export const NOTIFICATIONS_ROUTE = 'notifications'

const {
  VIEW,
} = BID

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(NOTIFICATIONS, NotificationsView, NOTIFICATIONS_ROUTE, {
      meta: {
        title: $t('Сповіщення'),
        ...accessPermission(VIEW),
      },
    }),
  ]
}
