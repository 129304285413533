/* eslint-disable no-useless-escape */
/**
 * get cookie
 * @param {*} name
 */
export function getCookie(name: string): string | undefined {
  const matches = document.cookie.match(
    new RegExp(
      //eslint-disable-next-line
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

const bakeCookie = (name: string, value: unknown, days: number): void => {
  const now = new Date()
  now.setDate(days)
  const cookie = [name, '=', JSON.stringify(value), ';expires', '=', now.toUTCString(), '; path=/;'].join('')
  document.cookie = cookie
}
const ONE_YEAR_DAYS_COUNT = 12 * 30
/**
 * set cookie without expired date
 * @param {*} name
 * @param {*} val
 */
export function setCookie(name: string, val: unknown, days = ONE_YEAR_DAYS_COUNT): void {
  bakeCookie(name, val, days)
}

/**
 * delete cookie
 * @param {*} name
 */

export function deleteCookie(name: string): void {
  document.cookie = `${name}=; max-age=-1; path=/;`
}
