import { createFriendlyUrl } from '@sub/src/utils/filters'
import { prefixLanguage } from '@sub/src/utils/urls'
import { intervalToDuration,differenceInCalendarDays } from 'date-fns'

export function getId(url: string): string | Error {
  //eslint-disable-next-line
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  const codeLength = 11
  if (match && match[2].length === codeLength) {
    return match[2]
  }
  return new Error('error parsed link')
}

export function getCompleteUrl(prefix: string, params: { [x: string]: any }): string {
  const filtersUrl = createFriendlyUrl(params)
  const url = prefixLanguage(`${prefix}${filtersUrl}`)
  return url
}

export function declension(count = 1, keywords: string[]): string {
  const lang = window.language
  const pr = new Intl.PluralRules(lang)
  const intlCount = pr.select(count)
  const collection: { [key: string]: string } = {
    one: `${count} ${keywords[0]}`,
    many: `${count} ${keywords[1]}`,
    few: `${count} ${keywords[2]}`,
  }
  return collection[intlCount]
}

export const plusDays =
(date: Date, count: number): Date => new Date(new Date(date).setDate(new Date(date).getDate() + count))

export const is = (k: string|number|undefined, v: string|number|string[]|number[]): boolean => {
  if (Array.isArray(v)) {
    return v.some((i: string|number) => k === i)
  }
  return k === v
}

export const rpl = (str: string): string => str?.replace(/\s+/g, '')

export const getGradientRange = (from: number[], to: number[], num: number): string[] => {
  const arr = []
  num--
  for (let i = 0; i <= num; i++) {
    const color = [],
      l = from.length
    for (let k = 0; k < l; k++) {
      color[k] = (to[k] - from[k]) * (i / num) + from[k]
      k < 3 && (color[k] = Math.round(color[k]))
    }
    arr[i] = 'rgb' + (l == 4 ? 'a(' : '(') + color + ')'
  }
  return arr
} 

export const openInNewTab = (href: string): void => {
  const link = Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href,
  })
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const isNotCorrectPeriod = (from: Date, to: Date): boolean => {
  const allDays = differenceInCalendarDays(
    new Date(from),
    new Date(to),
  )
  return allDays > 0
}

export const isSafariBrowser = (): boolean =>
  navigator.userAgent.indexOf('Safari') > -1
  && navigator.userAgent.indexOf('Chrome') <= -1

export const periodTransformer = (deliveryFrom: Date, deliveryTill: Date): string => {
  let result = ''
  const dict = {
    years: ['рік', 'років', 'роки'],
    months: ['місяць', 'місяців', 'місяця'],
    days: ['день', 'днів', 'дня'],
  }
  const { years, months, days } = intervalToDuration({
    start: new Date(deliveryFrom),
    end: plusDays(deliveryTill, 1),
  })
  const allDays: number = differenceInCalendarDays(
    plusDays(deliveryTill, 1),
    new Date(deliveryFrom),
  )
  if (!years && !months && !days) {
    result = declension(1, dict.days)
  } else {
    result = declension(
      days ? allDays : months ? months : years,
      days ? dict.days : months ? dict.months : dict.years,
    )      
  }
  return result
}
