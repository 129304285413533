<template>
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.4873 9.96875H15.7274" stroke="#773DBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.09277 19.1797H9.39541" stroke="#773DBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.2734 19.1797H16.8787" stroke="#773DBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.5136 14.0356V18.7329C25.5136 22.7741 24.4889 23.7872 20.4018 23.7872H7.59915C3.51198 23.7872 2.4873 22.7741 2.4873 18.7329V9.26912C2.4873 5.228 3.51198 4.21484 7.59915 4.21484H15.7274" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.1816 7.37891H25.5139" stroke="#773DBD" stroke-width="2" stroke-linecap="round"/>
<path d="M22.3477 10.5471V4.21484" stroke="#773DBD" stroke-width="2" stroke-linecap="round"/>
</svg>
</template>
