import { App, defineAsyncComponent } from 'vue'

const View = defineAsyncComponent(() => import('./View.vue'))

const register = (app: App<Element>): void => {
  app
    .component('ProductsChart', View)
}

export default {
  register,
}
