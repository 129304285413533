import {
  createSender,
  r,
} from '@resource/resource'

const MODEL = 'offers'

// DIVIDED
const MODEL_DIVIDED = `${MODEL}/divided`

export const offers = {
  list: r(`${MODEL_DIVIDED}/list/{?filters,order_by,limit,offset}`),
  tradersList: r(`${MODEL}/list/{?filters,order_by,is_owned,is_date_top,limit,offset}`),
  filters: {
    list: r(`${MODEL_DIVIDED}/filters/list/`),
    available: r(`${MODEL_DIVIDED}/filters/list/{?filters}`),
    tradersList: r(`${MODEL}/filters/list/`),
    tradersAvailable: r(`${MODEL}/filters/list/{?filters,is_owned}`),
  },
}

// OWNED
const MODEL_OWNED = `${MODEL}/owner`

export const ownedOffers = {
  list: r(`${MODEL_OWNED}/list/{?filters,order_by,is_owned,is_date_top,limit,offset}`),
  filters: {
    list: r(`${MODEL_OWNED}/filters/list/`),
    available: r(`${MODEL_OWNED}/filters/list/{?filters,is_owned}`),
  },
}

export const offer = {
  create: r(`${MODEL}/create/`, createSender).config('method', 'POST'),
  retrieve: r(`${MODEL}/{offer}/retrieve/{?filters}`),
  retrieveOrChild: r(`${MODEL}/{offer}/retrieve/or-child/{?filters}`),
  update: r(`${MODEL}/{offer}/update/`, createSender).config('method', 'PATCH'),
  archive: r(`${MODEL}/{offer}/archive/`, createSender).config('method', 'PATCH'),
  twoFactorRequest: r(`${MODEL}/2factor/request-confirmation/`, createSender).config('method', 'POST'),
  twoFactorConfirm: r(`${MODEL}/2factor/confirm/`, createSender).config('method', 'POST'),
  broadcast: r(`${MODEL}/update/broadcast/`, createSender).config('method', 'POST'),
}

// SELLER OFFERS
const MODEL_SELLER = `${MODEL}/seller`

export const sellerOffers = {
  list: r(`${MODEL_SELLER}/list/{?filters,order_by,limit,offset}`),
  filters: {
    list: r(`${MODEL_SELLER}/filters/list/`),
    available: r(`${MODEL_SELLER}/filters/list/{?filters}`),
  },
}
