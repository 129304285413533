function documentOffsetTop(element: HTMLElement): number {
  return element.offsetTop + (element.offsetParent ? documentOffsetTop(element.offsetParent as HTMLElement) : 0);
}

export const scroller = async (): Promise<void> => {
  const { hash } = window.location
  if (!hash) return
  const [, id] = hash.split('#')
  setTimeout(async () => {
    const offset = 0
    const targetElement = document.getElementById(id)
    if (targetElement) {
      const top = documentOffsetTop(targetElement) - offset
      window.scrollTo({ top, behavior: 'smooth' })

    }
  }, 500)
}
