import { App, defineAsyncComponent } from 'vue'

import UiModalsContainer from './Modals/Container.vue'
import UiSimpleModalsContainer from './Modals/SimpleContainer.vue'

const ModalTrigger = defineAsyncComponent(() => import('./Modals/ModalTrigger.vue'))
const LogoutTrigger = defineAsyncComponent(() => import('./LogoutTrigger.vue'))
const Preloader = defineAsyncComponent(() => import('./Preloader.vue'))

const register = (app: App<Element>): void => {
  app
    .component('UiModalsContainer', UiModalsContainer)
    .component('UiSimpleModalsContainer', UiSimpleModalsContainer)

    .component('ModalTrigger', ModalTrigger)
    .component('LogoutTrigger', LogoutTrigger)
    .component('Preloader', Preloader)
}

export default {
  register,
}
