import { DEAL_DETAILS } from '@/router/Common/consts'
import { SELLER_DEAL_DETAILS } from '@/router/Seller/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DEALS } from '@md/users/index'

const DealDetails = () => import('@components/Profiles/Common/Deals/Details/View.vue')

export const DEALS_DETAILS_ROUTE = 'deals-details'
export const SELLER_DEALS_DETAILS_ROUTE = 'seller-deals-details'
const {
  VIEW,
} = DEALS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(DEAL_DETAILS, DealDetails, DEALS_DETAILS_ROUTE, {
      props: true,
      meta: {
        title: $t('Угода'),
        ...accessPermission(VIEW),
      },
    }),
    useRoute(SELLER_DEAL_DETAILS, DealDetails, SELLER_DEALS_DETAILS_ROUTE, {
      props: true,
      meta: {
        title: $t('Угода'),
        ...accessPermission(VIEW),
      },
    }),
  ]
}
