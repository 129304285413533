import { App } from 'vue'

import i18n from './i18n'
import Validation from './validation/plugin'
import VueFinalModal from './vue-final-modal'
import VueMask from './vue-inputmask'
import VueSocialSharing from './vue-social-sharing'
import VueClipboard from './vue-clipboard'
import VueSlider from './vue-slider-component'
import Tippy from './vue-tippy'
import VueObserveVisibility from './vue-observe-visibility'

export default {
  install(app: App<Element>): any {
    app
      .use(i18n)
      .use(Validation)
      .use(VueFinalModal)
      .use(VueMask)
      .use(VueSocialSharing)
      .use(VueClipboard)
      .use(VueSlider)
      .use(Tippy)
      .use(VueObserveVisibility)
  }
}
