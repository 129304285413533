import { ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import { OFFER_CHANGE } from '@components/Profiles/Common/Bargaining/DataFeed/const'
import instant from '@components/Profiles/Common/Bargaining/DataFeed/centrifugo'
import Message from '@utils/centrifugo/message'
import { is } from '@sub/src/utils/helpers'

type EventTrigger = {
  room: boolean,
}

export const useBargainStore = defineStore('bargain', () => {

  const eventTriggers: Ref<EventTrigger> = ref({ room: false })
  const bargainRoom: Ref<number|null> = ref(null)

  async function initCent(this: any) {
    if (window.isAuthenticated) {
      (async () => {
        await instant.getToken()
        await instant.connect()
      })()
      instant.onMessage = handleEvent.bind(this)
    }
  }

  const handleEvent = (event: Message) => {
    const { eventClass, data } = event
    // console.log(eventClass, data?.id_room, 'HANDLE EVENT')
    if (is(eventClass, OFFER_CHANGE)) {
      // console.log('HANDLE OFFER_CHANGE')
      eventTriggers.value.room = !eventTriggers.value.room
      bargainRoom.value = data?.id_room
    }
  }
  return {
    eventTriggers,
    bargainRoom,
    initCent,
  }
})
