<template>
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1575_19733)">
<path d="M8.7526 15.6224C12.4346 15.6224 15.4193 12.6377 15.4193 8.95573C15.4193 5.27373 12.4346 2.28906 8.7526 2.28906C5.0706 2.28906 2.08594 5.27373 2.08594 8.95573C2.08594 12.6377 5.0706 15.6224 8.7526 15.6224Z" stroke="#6ABF4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.75195 8.95443L8.08529 10.2878L10.752 7.62109" stroke="#6ABF4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1575_19733">
<rect width="16" height="16" fill="white" transform="translate(0.751953 0.955078)"/>
</clipPath>
</defs>
</svg>
</template>
