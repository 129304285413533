<template>
<svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_885_1063)">
<path d="M14.25 3.13721H3.75C2.92157 3.13721 2.25 3.80878 2.25 4.63721V15.1372C2.25 15.9656 2.92157 16.6372 3.75 16.6372H14.25C15.0784 16.6372 15.75 15.9656 15.75 15.1372V4.63721C15.75 3.80878 15.0784 3.13721 14.25 3.13721Z" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 1.63721V4.63721" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 1.63721V4.63721" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.25 7.63721H15.75" stroke="#773DBD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_885_1063">
<rect width="18" height="18" fill="white" transform="translate(0 0.137207)"/>
</clipPath>
</defs>
</svg>
</template>
