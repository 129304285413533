import { OWNED_ORDERS_CATALOG } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { ORDERS } from '@md/users/index'

const OwnedPurchaseOrdersCatalog = () => import('@components/Profiles/Common/PurchaseOrders/Views/OwnedOrders/View.vue')

export const OWNED_ORDERS_ROUTE = 'owned-orders'
const {
  CHANGE,
} = ORDERS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(OWNED_ORDERS_CATALOG, OwnedPurchaseOrdersCatalog, OWNED_ORDERS_ROUTE, {
      props: true,
      meta: {
        title: $t('Мої заявки на купівлю газу'),
        ...accessPermission(CHANGE),
      },
    }),
  ]
}
