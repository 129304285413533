import { ref, unref, computed, Ref } from 'vue'


export function useLoadingState() {
  const state = ref(0)
  const loading = computed(() => state.value > 0)
  const load = function <T extends Promise<any>>(promise: T): T {
    state.value += 1
    promise.finally(() => {
      state.value -= 1
    })

    return promise
  }

  return { state, loading, load }
}

export type LoadingState = ReturnType<typeof useLoadingState>

export function mergeLoadingStates(states: { loading: Ref<boolean> | boolean }[]) {
  const loading = computed(() => states.reduce(
    (acc, state) => acc || unref(state.loading),
    false
  ))

  return loading
}

export function useLoadable<T = any>(callable: any, {
  cancellable = true
} = {}) {
  const loading = useLoadingState()
  const result: Ref<T | null> = ref(null)
  const lastPromise: Ref<Promise<T> & { cancel?: () => void } | null> = ref(null)

  return {
    result, loading: loading.loading,
    load: (...args: any[]) => {
      if (cancellable && lastPromise.value && lastPromise.value.cancel) {
        lastPromise.value.cancel()
      }

      const promise: Promise<T> = loading.load(
        callable(...args).then((r: any) => {
          result.value = r || null
          return r
        })
      )

      return promise
    }
  }
}

export type Loadable = ReturnType<typeof useLoadable>
