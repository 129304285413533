import { $vfm } from 'vue-final-modal'

export type Close = () => Promise<string>

export type Info = {
  title?: string,
  text?: string,
  additionalInfo?: string,
}

const PATH_NAME = 'Profiles/Modals/'
export const p = (name: string, path = PATH_NAME): string => path + name

export const useModalOpener = (
  params: Record<string, unknown>,
  component = 'UiModalsContainer',
): any => $vfm.show({
  component,
}, { 
  ...params,
})

export const useShowModal = (
  message: Info,
  params?: Record<string, unknown>,
  cls = ['modal--size_md'],
  component = 'UiElements/Modals/UiMessageModal',
): void => {
  useModalOpener({
    component,
    message,
    ...params,
    classes: cls,
  })
}

export function useConfirmWithPromise(
  info: unknown,
  comp = 'UiElements/Modals/UiConfirmModal',
  cls = ['modal--size_sm'],
): any {
  return new Promise((res, rej) => {
    const pr = (arg: any) => new Promise((resolve, reject) => {
      if (true == arg) {
        resolve(arg)
      } else {
        reject(new Error('...'))
      }
    }).then(result => {
      res(result)
    }, err => {
      rej()
    })
    useModalOpener({
      component: comp,
      classes: cls,
      info,
      promise: pr,
    })
  })
}
