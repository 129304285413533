import { SELLER_COMPANY } from '@/router/Seller/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { COMPANY } from '@md/users/index'

const CompanyView = () => import('@components/Profiles/Seller/Companies/Views/Details/View.vue')

export const SELLER_COMPANY_ROUTE = 'seller-company'

const {
  VIEW_SELLER,
} = COMPANY

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(SELLER_COMPANY, CompanyView, SELLER_COMPANY_ROUTE, {
      props: true,
      meta: {
        title: $t('Компанія'),
        ...accessPermission(VIEW_SELLER),
      },
    }),
  ]
}
