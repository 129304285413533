import createRouter from '@/router'
import Components from '@/components'
import Modules from '@/modules'
import Submodules from '@sub/src/main'
import Directives from '@/directives'
import sentryInit from '@/sentry'
import { aosInitiate, eventStarter } from '@sub/src/utils'
import { COMPANY_DATE, PLAT4MA_HAS_COMPANY, STEP_TWO_CASHED, NOVAPOSHTA_INFO_CASHED } from '@/const'

if (window.isMain) {
  aosInitiate()
  eventStarter()
}

type createApplication = { createApp: any, createRouter: any, createWebHistory: any, createPinia: any }

export function createApplication(
  {
    createApp,
    createRouter: createInstance,
    createWebHistory,
    createPinia,
  }:createApplication
): any {
  const app = createApp({
    delimiters: ['[[', ']]'],
    data() {
      return {
        date: new Date(),
        True: true,
        False: false,
        None: undefined,
      }
    },
  })
  const pinia = createPinia()
  app.use(pinia)

  app.use(Modules)

  const router = createRouter({ app, createInstance, createWebHistory, pinia })
  app.use(router)

  Submodules.install(app)
  Components.installComponents(app)
  Directives.registerDirective(app)

  sentryInit(app)

  // eslint-disable-next-line
  const { getCookie } = require('@sub/src/utils/cookies')
  if (!getCookie(PLAT4MA_HAS_COMPANY)) {
    localStorage.removeItem(COMPANY_DATE)
    localStorage.removeItem(STEP_TWO_CASHED)
    localStorage.removeItem(NOVAPOSHTA_INFO_CASHED)
  }

  app.mount('#app')
  return app
}
