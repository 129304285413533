import { PermissionsAccessState } from '@aspectus/vue-permissions';
import { collectRoutePermissionCheckers } from './routing';

export default {
  name: 'route-access-state',
  props: { to: {} },

  computed: {
    permissions() {
      return collectRoutePermissionCheckers(this.$router.matcher.match(this.to)).map(x => x[0]);
    },
  },

  render(h) {
    return h(
      PermissionsAccessState,
      {
        props: {
          permissions: this.permissions,
        },
        slots: {
          default: props => this.$slots.default(props),
        },
      }
    );
  },
};
