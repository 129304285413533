import { App } from 'vue'

import { Form, Field, ErrorMessage, defineRule } from '.'
import {
  required,
  email,
  confirmed,
  min,
  minNoSpaces,
  max,
  alphaValidator,
  arrayLengthMax,
  accountingEPoint,
  accountingZPoint,
  isEPoint,
  isZPoint,
  customPassword,
  customTime,
  multiplicityСheck,
  maskedPhone,
  maxValueValidatorNoSpaces,
  minValueValidatorNoSpaces,
  eicCheck,
  checkArchivedAt,
} from './rules'

export default {
  install(app: App<Element>): any {
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeError', ErrorMessage)

    defineRule('required', required)
    defineRule('email', email)
    defineRule('confirmed', confirmed)
    defineRule('min', min)
    defineRule('max', max)
    defineRule('alpha', alphaValidator)
    defineRule('arrayLengthMax', arrayLengthMax)
    defineRule('accountingEPoint', accountingEPoint)
    defineRule('accountingZPoint', accountingZPoint)
    defineRule('isEPoint', isEPoint)
    defineRule('isZPoint', isZPoint)
    defineRule('customPassword', customPassword)
    defineRule('minNoSpaces', minNoSpaces)
    defineRule('customTime', customTime)
    defineRule('multiplicityСheck', multiplicityСheck)
    defineRule('maskedPhone', maskedPhone)
    defineRule('noSpacesMax', maxValueValidatorNoSpaces)
    defineRule('noSpacesMin', minValueValidatorNoSpaces)
    defineRule('eicCheck', eicCheck)
    defineRule('checkArchivedAt', checkArchivedAt)
  }
}
