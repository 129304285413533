import { App, defineAsyncComponent } from 'vue'

const VideoController = defineAsyncComponent(() => import('./VideoController.vue'))

const register = (app: App<Element>): void => {
  app
    .component('VideoController', VideoController)
}

export default {
  register,
}
