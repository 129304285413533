import {
  DEAL,
  DEAL_STEP_ONE,
  DEAL_STEP_TWO,
  DEAL_STEP_THREE,
} from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DEALS } from '@md/users/index'

const DealView = () => import('@components/Profiles/Common/Deals/Create/View.vue')
const DealStepOne = () => import('@components/Profiles/Common/Deals/Create/StepOne.vue')
const DealStepTwo = () => import('@components/Profiles/Common/Deals/Create/StepTwo.vue')
const DealStepThree = () => import('@components/Profiles/Common/Deals/Create/StepThree.vue')


const ROUTE_NAME = 'deal'

const p = (name: string) => ROUTE_NAME + name

export const ONE = p(':one')
export const TWO = p(':two')
export const THREE = p(':three')

const {
  CREATE,
} = DEALS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(DEAL, DealView, ROUTE_NAME, {
      redirect: { name: ONE },
      props: true,
      meta: {
        title: $t('Оформлення угоди'),
        ...accessPermission(CREATE),
      },
      children: [
        useRoute(DEAL_STEP_ONE, DealStepOne, ONE, { props: true }),
        useRoute(DEAL_STEP_TWO, DealStepTwo, TWO, { props: true }),
        useRoute(DEAL_STEP_THREE, DealStepThree, THREE, { props: true }),
      ],      
    }),
  ]
}
