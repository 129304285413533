import { App } from 'vue'

import Common from './Common'

const register = (app: App<Element>): void => {
  Common.register(app)
}

export default {
  register,
}
