<template>
<svg fill="#56D0E0" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	width="800px" height="800px" viewBox="0 0 880.899 880.899"
	xml:space="preserve">
<g>
	<path d="M530.25,537.2c9.9-48.7,25.2-124.301,25.3-124.4c28.9-33.9,46.7-173.9,46.7-225.3C602.25,83.9,529.85,0,440.45,0
		s-161.8,84-161.8,187.5c0,51.4,17.8,191.399,46.7,225.3c0,0,15.399,75.7,25.3,124.4H530.25z"/>
	<path d="M73.95,793.1c-0.7,0-1.4,0-2.1-0.1v37.899c0,27.601,22.4,50,50,50h637.2c27.601,0,50-22.399,50-50V793
		c-0.699,0-1.399,0.1-2.1,0.1H73.95z"/>
	<path d="M824.25,755.8c5.601-2.1,10.7-5.1,15.2-8.9c10.7-9.199,17.5-22.8,17.5-38V621.5c0-27.601-22.4-50-50-50h-283.6H357.549
		H73.95c-27.6,0-50,22.399-50,50v87.399c0,15.2,6.8,28.801,17.5,38c4.5,3.801,9.6,6.801,15.2,8.9c4.8,1.8,9.9,2.8,15.2,3
		c0.7,0,1.4,0.1,2.1,0.1h732.9c0.699,0,1.399,0,2.1-0.1C814.35,758.6,819.45,757.5,824.25,755.8z"/>
</g>
</svg>
</template>