import { ORDERING_VOLUMES } from '@/router/Business/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DEAL_POINT_SUM } from '@md/users/index'

const OrderingVolumes = () => import('@components/Profiles/Business/OrderingVolumes/View.vue')

export const ORDERING_ROUTE = 'ordering'
const {
  VIEW,
} = DEAL_POINT_SUM

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(ORDERING_VOLUMES, OrderingVolumes, ORDERING_ROUTE, {
      props: true,
      meta: {
        title: $t('Замовлення/корегування обсягів газу'),
        ...accessPermission(VIEW),
      },
    }),
  ]
}
