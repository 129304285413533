const messages = {
  [window.language]: (window.django && window.django.catalog) || {},
}

interface CompileMessages {
  locale: string
  messages: typeof messages
}

export const compileMessages = (): CompileMessages => ({
  locale: window.language,
  messages,
})
