import { ref, computed, Ref, ComputedRef } from 'vue'
import { defineStore } from 'pinia'
import { userRetrive } from '@services/profile.service'
import { getStats as getStatsRes } from '@services/notifications.service'
import { User as UserClass } from './base/user'
import { Stats } from '@md/users/types'

export const useUserStore = defineStore('user', () => {
  const user: Ref<UserClass> = ref(new UserClass({
    basePermissions: [],
    companies: [],
    counters: {},
    currentCompany: undefined,
    email: '',
    firstName: '',
    id: 0,
    is2f: false,
    lastName: '',
    phoneNumber: '',
  }, false))

  const statsInfo: Ref<Stats> = ref({ flags: {} })

  const getUserInfo: ComputedRef<UserClass> = computed(() => user.value)
  const getStatsInfo: ComputedRef<Stats> = computed(() => statsInfo.value)

  const getUserData = async () => {
    try {
      const { data: { item } } = (await userRetrive.execute({}))
      const { USER: { activeCompany } } = window
      const { companies } = item
      const currentCompany = companies.find((el:any) => el.id == activeCompany?.id)
      user.value = new UserClass({...item, currentCompany})
    } catch (e) {
      console.error(e)
    }
  }

  const getStats = async (): Promise<Stats | undefined> => {
    try {
      const res = (await getStatsRes.execute({}))
      if (res.length) {
        const [first] = res as Stats[]
        return statsInfo.value = first
      }
      return statsInfo.value
    } catch (e) {
      console.error(e)
      return undefined
    }
  }
  return {
    user,
    statsInfo,
    getUserInfo,
    getStatsInfo,
    getUserData,
    getStats,
  }
})
