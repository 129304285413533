import { App, defineAsyncComponent } from 'vue'

const Share = defineAsyncComponent(() => import('./Share.vue'))
const ToggleShare = defineAsyncComponent(() => import('./ToggleShare.vue'))

const register = (app: App<Element>): void => {
  app
    .component('Share', Share)
    .component('ToggleShare', ToggleShare)
}

export default {
  register,
}
