<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 1.5V3.75" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 1.5V3.75" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.625 6.81641H15.375" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.75 6.375V12.75C15.75 15 14.625 16.5 12 16.5H6C3.375 16.5 2.25 15 2.25 12.75V6.375C2.25 4.125 3.375 2.625 6 2.625H12C14.625 2.625 15.75 4.125 15.75 6.375Z" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.99686 10.2734H9.00359" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.22049 10.2734H6.22723" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.22049 12.5234H6.22723" stroke="#292D32" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
