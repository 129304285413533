import { SELLER_COMPANIES } from '@/router/Seller/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { COMPANY } from '@md/users/index'

const CompaniesCatalog = () => import('@components/Profiles/Seller/Companies/Views/Catalog/View.vue')

export const SELLER_COMPANIES_ROUTE = 'seller-companies'

const {
  VIEW_SELLER,
} = COMPANY

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(SELLER_COMPANIES, CompaniesCatalog, SELLER_COMPANIES_ROUTE, {
      props: true,
      meta: {
        title: $t('Компанії'),
        ...accessPermission(VIEW_SELLER),
      },
    }),
  ]
}
