import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { createRoutes as createSellerDealsRoutes } from '@components/Profiles/Seller/Deals/router'
import { createRoutes as createSellerOffersRoutes } from '@components/Profiles/Seller/Offers/router'
import { createRoutes as createSellerPurchaseOrdersRoutes } from '@components/Profiles/Seller/PurchaseOrders/router'
import { createRoutes as createSellerBargainingRoutes } from '@components/Profiles/Seller/Bargaining/router'
import { createRoutes as createSellerCompaniesRoutes } from '@components/Profiles/Seller/Companies/Views/Catalog/router'
import { createRoutes as createSellerCompanyRoutes } from '@components/Profiles/Seller/Companies/Views/Details/router'
import { createRoutes as createSellerUsersRoutes } from '@components/Profiles/Seller/Users/router'

const NotFoundView = () => import('@components/Profiles/Common/NotFound/404.vue')

export function createRoutes(options:optionsT): any {
  return [
    ...createSellerDealsRoutes(options),
    ...createSellerOffersRoutes(options),
    ...createSellerPurchaseOrdersRoutes(options),
    ...createSellerBargainingRoutes(options),
    ...createSellerCompaniesRoutes(options),
    ...createSellerCompanyRoutes(options),
    ...createSellerUsersRoutes(options),
    useRoute('/:pathMatch(.*)*', NotFoundView, '404'),
  ]
}
