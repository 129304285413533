<template>
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.30273 9.78516H16.6934" stroke="#773DBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.90723 18.9961H9.20973" stroke="#773DBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.0879 18.9961H16.6929" stroke="#773DBD" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.3277 16.1539V18.5485C25.3277 22.5894 24.3031 23.6025 20.2162 23.6025H7.41428C3.32735 23.6025 2.30273 22.5894 2.30273 18.5485V9.08524C2.30273 5.04435 3.32735 4.03125 7.41428 4.03125H16.6934" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.0254 4.03125V10.9387L25.3279 8.63625" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M23.0252 10.9353L20.7227 8.63281" stroke="#773DBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>
