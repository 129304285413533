import { App } from 'vue'

import Controller from './Controller.vue'

const register = (app: App<Element>): void => {
  app
    .component('FaqController', Controller)
}

export default {
  register,
}
