import { App } from 'vue'
import VueTippy from 'vue-tippy'

export default {
  install(app: App<Element>): any {
    app.use(VueTippy, {
      defaultProps: { theme: 'main', zIndex: 2147483646 },
    })
  }
}
