import { App } from 'vue'
import { createI18n } from 'vue-i18n'
import { compileMessages } from '@md/i18n/compileMessages'

const dateTimeFormats = {}

const i18nOptions = {
  ...compileMessages(),
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  legacy: false,
  dateTimeFormats,
}

export const i18n = createI18n(i18nOptions)

export default {
  install(app: App<Element>): void {
    app.use(i18n)
  }
}
