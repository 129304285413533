<template>
<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1575_19502)">
<path d="M16.5518 12.6901V14.9401C16.5527 15.1489 16.5099 15.3557 16.4262 15.5471C16.3425 15.7385 16.2198 15.9103 16.0659 16.0515C15.912 16.1927 15.7303 16.3002 15.5324 16.3671C15.3345 16.434 15.1249 16.4589 14.9168 16.4401C12.609 16.1893 10.3921 15.4007 8.44433 14.1376C6.6322 12.9861 5.09583 11.4497 3.94433 9.63757C2.67681 7.68098 1.88801 5.45332 1.64183 3.13507C1.62309 2.92767 1.64774 2.71864 1.71421 2.52129C1.78067 2.32394 1.88751 2.14259 2.0279 1.98879C2.1683 1.83499 2.33918 1.7121 2.52967 1.62796C2.72016 1.54382 2.92609 1.50027 3.13433 1.50007H5.38433C5.74831 1.49649 6.10117 1.62538 6.37715 1.86272C6.65313 2.10006 6.83339 2.42966 6.88433 2.79007C6.9793 3.51012 7.15542 4.21712 7.40933 4.89757C7.51024 5.16602 7.53208 5.45776 7.47226 5.73823C7.41244 6.01871 7.27348 6.27616 7.07183 6.48007L6.11933 7.43257C7.187 9.31023 8.74167 10.8649 10.6193 11.9326L11.5718 10.9801C11.7757 10.7784 12.0332 10.6395 12.3137 10.5796C12.5941 10.5198 12.8859 10.5417 13.1543 10.6426C13.8348 10.8965 14.5418 11.0726 15.2618 11.1676C15.6262 11.219 15.9589 11.4025 16.1967 11.6832C16.4346 11.9639 16.561 12.3223 16.5518 12.6901Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1575_19502">
<rect width="18" height="18" fill="white" transform="translate(0.0517578)"/>
</clipPath>
</defs>
</svg>
</template>
