import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { createRoutes as createCompanyRoutes } from '@components/Profiles/Common/Company/router'
import { createRoutes as createSettingsRoutes } from '@components/Profiles/Common/Settings/router'
import { createRoutes as createMyCompaniesRoutes } from '@components/Profiles/Common/MyCompanies/router'
import { createRoutes as createOffersCatalogRoutes } from '@components/Profiles/Common/OffersCatalog/router'
import { createRoutes as createVerificationRoutes } from '@components/Profiles/Common/EnterpriseVerification/router'
import { createRoutes as createNotificationsRoutes } from '@components/Profiles/Common/Notifications/router'
import { createRoutes as createOwnedOrdersCreateRoutes } from '@components/Profiles/Common/PurchaseOrders/Views/OwnedOrders/router'
import { createRoutes as createCreateOrdersCreateRoutes } from '@components/Profiles/Common/PurchaseOrders/Views/Create/router'
import { createRoutes as createDealsCatalogRoutes } from '@components/Profiles/Common/Deals/Catalog/router'
import { createRoutes as createDealsCreateRoutes } from '@components/Profiles/Common/Deals/Create/router'
import { createRoutes as createDealsDetailsRoutes } from '@components/Profiles/Common/Deals/Details/router'
import { createRoutes as createDocumentsRoutes } from '@components/Profiles/Common/Documents/Catalog/router'
import { createRoutes as createDocumentsDetailsRoutes } from '@components/Profiles/Common/Documents/Details/router'
import { createRoutes as createWhatNewRoutes } from '@components/Profiles/Common/WhatNew/router'
import { createRoutes as createBargainingRoutes } from '@components/Profiles/Common/Bargaining/router'
import { createRoutes as createPaymentsRoutes } from '@components/Profiles/Common/Documents/Payments/router'

const NotFoundView = () => import('@components/Profiles/Common/NotFound/404.vue')

export function createRoutes(options:optionsT): any {
  return [
    ...createSettingsRoutes(options),
    ...createWhatNewRoutes(options),
    ...createMyCompaniesRoutes(options),
    ...createCompanyRoutes(options),
    ...createOffersCatalogRoutes(options),
    ...createVerificationRoutes(options),
    ...createNotificationsRoutes(options),
    ...createOwnedOrdersCreateRoutes(options),
    ...createCreateOrdersCreateRoutes(options),
    ...createDealsCatalogRoutes(options),
    ...createDealsCreateRoutes(options),
    ...createDealsDetailsRoutes(options),
    ...createDocumentsRoutes(options),
    ...createDocumentsDetailsRoutes(options),
    ...createBargainingRoutes(options),
    ...createPaymentsRoutes(options),
    useRoute('/:pathMatch(.*)*', NotFoundView, '404'),
  ]
}
