import {
  baseResource,
} from '@resource/resource'

import {
  friendlyUrlGenerator,
} from '@aspectus/vue-friendly'

import {
  prefixLanguage,
} from '@sub/src/utils/urls'

const order = {
  path: [
    'prefix',
    'category',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

const FILTERS_LIST_URL_PREFIX = prefixLanguage('/blog')
const FAQ_FILTERS_LIST_URL_PREFIX = prefixLanguage('/faq')
const FILTERS_LIST_URL_POSTFIX = '/ajax/filters/'
export const filesListUrl =
  friendlyUrlGenerator([FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)

export const faqListUrl =
  friendlyUrlGenerator([FAQ_FILTERS_LIST_URL_PREFIX, FILTERS_LIST_URL_POSTFIX], baseResource, order)
