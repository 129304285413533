import {
  VERIFICATION,
  STEP_ONE,
  STEP_TWO,
  STEP_THREE,
  STEP_FOUR,
  STEP_FIVE,
} from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { BID } from '@md/users/index'

const EnterpriseVerificationView = () => import('@components/Profiles/Common/EnterpriseVerification/View.vue')
const VerificationStepOne = () => import('@components/Profiles/Common/EnterpriseVerification/StepOne.vue')
const VerificationStepTwo = () => import('@components/Profiles/Common/EnterpriseVerification/StepTwo.vue')
const VerificationStepThree = () => import('@components/Profiles/Common/EnterpriseVerification/StepThree.vue')
const VerificationStepFour = () => import('@components/Profiles/Common/EnterpriseVerification/StepFour.vue')
const VerificationStepFive = () => import('@components/Profiles/Common/EnterpriseVerification/StepFive.vue')

const ROUTE_NAME = 'verification'

const p = (name: string) => ROUTE_NAME + name

export const ONE = p(':one')
export const TWO = p(':two')
export const THREE = p(':three')
export const FOUR = p(':four')
export const FIVE = p(':five')

const {
  VIEW,
} = BID

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(VERIFICATION, EnterpriseVerificationView, ROUTE_NAME, {
      redirect: { name: ONE },
      props: true,
      meta: {
        title: $t('Верифікація підприємства'),
        ...accessPermission(VIEW),
      },
      children: [
        useRoute(STEP_ONE, VerificationStepOne, ONE, { props: true }),
        useRoute(STEP_TWO, VerificationStepTwo, TWO, { props: true }),
        useRoute(STEP_THREE, VerificationStepThree, THREE, { props: true }),
        useRoute(STEP_FOUR, VerificationStepFour, FOUR, { props: true }),
        useRoute(STEP_FIVE, VerificationStepFive, FIVE, { props: true }),
      ],      
    }),
  ]
}
