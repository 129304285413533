import { SELLER_ORDERS_CATALOG } from '@/router/Seller/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { ORDERS } from '@md/users/index'

const PurchaseOrdersCatalog = () => import('@components/Profiles/Seller/PurchaseOrders/View.vue')

export const SELLER_ORDERS_ROUTE = 'seller-orders'
const {
  VIEW_ADMIN,
} = ORDERS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(SELLER_ORDERS_CATALOG, PurchaseOrdersCatalog, SELLER_ORDERS_ROUTE, {
      props: true,
      meta: {
        title: $t('Заявки на купівлю газу'),
        ...accessPermission(VIEW_ADMIN),
      },
    }),
  ]
}
