import { App } from 'vue'

import Icons from './i'
import Forms from './Forms'
import UiElements from './UiElements'
import Common from './Common'
import Profiles from './Profiles'
import Charts from './Charts'

const installComponents = (app: App<Element>): void => {
  Icons.register(app)
  Forms.register(app)
  UiElements.register(app)
  Common.register(app)
  Profiles.register(app)
  Charts.register(app)
}

export default {
  installComponents,
}
