import { ORDERS_CATALOG } from '@/router/Trader/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { ORDERS } from '@md/users/index'

const PurchaseOrdersCatalog = () => import('@components/Profiles/Trader/PurchaseOrders/View.vue')

export const ORDERS_ROUTE = 'orders'
const {
  VIEW,
} = ORDERS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(ORDERS_CATALOG, PurchaseOrdersCatalog, ORDERS_ROUTE, {
      props: true,
      meta: {
        title: $t('Заявки на купівлю газу'),
        ...accessPermission(VIEW),
      },
    }),
  ]
}
