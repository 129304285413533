<template>
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1517_9534)">
<path d="M3.16797 9.11979V2.78646C3.16797 2.43284 3.30844 2.0937 3.55849 1.84365C3.80854 1.5936 4.14768 1.45313 4.5013 1.45312H10.168L13.8346 5.11979V13.4531C13.8346 13.8067 13.6942 14.1459 13.4441 14.3959C13.1941 14.646 12.8549 14.7865 12.5013 14.7865H8.83464" stroke="#56D0E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.83203 1.45312V5.45313H13.832" stroke="#56D0E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.44797 8.52531C7.57798 8.3953 7.73232 8.29217 7.90218 8.22181C8.07205 8.15145 8.25411 8.11523 8.43797 8.11523C8.62183 8.11523 8.80389 8.15145 8.97375 8.22181C9.14362 8.29217 9.29796 8.3953 9.42797 8.52531C9.55798 8.65531 9.66111 8.80966 9.73147 8.97952C9.80183 9.14939 9.83804 9.33145 9.83804 9.51531C9.83804 9.69917 9.80183 9.88123 9.73147 10.0511C9.66111 10.221 9.55798 10.3753 9.42797 10.5053L5.8013 14.1186L3.16797 14.7853L3.82797 12.152L7.44797 8.52531Z" stroke="#56D0E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1517_9534">
<rect width="16" height="16" fill="white" transform="translate(0.5 0.119141)"/>
</clipPath>
</defs>
</svg>
</template>
