import { App, defineAsyncComponent } from 'vue'
import Controller from './Controls/ControlDescriptor.vue'
import InputLabel from './Controls/Label.vue'
import InputTextArea from './Controls/TextArea.vue'
import SimpleInput from './Controls/SimpleInput.vue'
import Multiselect from './Controls/Multiselect.vue'
import Switcher from './Controls/Switcher.vue'
import RangeWidget from './Controls/RangeWidget.vue'
import MaskedFloatInput from './Controls/MaskedFloatInput.vue'

import UiSlider from './UiSlider.vue'
import UiTabs from './UiTabs.vue'
import { OnClickOutside } from '@vueuse/components'
import AppIcon from './AppIcon.vue'

const SidebarTrigger = defineAsyncComponent(() => import('./SidebarTrigger.vue'))
const UiAccordion = defineAsyncComponent(() => import('./UiAccordion.vue'))
const CButton = defineAsyncComponent(() => import('./Controls/ControlButton.vue'))
const ContentToggler = defineAsyncComponent(() => import('./ContentToggler.vue'))
const UiScrollListener = defineAsyncComponent(() => import('./UiScrollListener.vue'))

const register = (app: App<Element>): void => {
  app
    .component('DController', Controller)
    .component('InputLabel', InputLabel)
    .component('InputTextArea', InputTextArea)
    .component('SimpleInput', SimpleInput)
    .component('Multiselect', Multiselect)
    .component('Switcher', Switcher)
    .component('RangeWidget', RangeWidget)
    .component('MaskedFloatInput', MaskedFloatInput)

    .component('UiSlider', UiSlider)
    .component('UiTabs', UiTabs)
    .component('OnClickOutside', OnClickOutside)
    .component('AppIcon', AppIcon)

    .component('SidebarTrigger', SidebarTrigger)
    .component('UiAccordion', UiAccordion)
    .component('ControlButton', CButton)
    .component('UiScrollListener', UiScrollListener)
    .component('ContentToggler', ContentToggler)
}

export default {
  register,
}
