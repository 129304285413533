import { CREATE_OFFER, DETAIL_OFFER, UPDATE_OFFER } from '@/router/Trader/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { OFFERS } from '@md/users/index'

const CreateOffersView = () => import('./View.vue')

const ROUTE_NAME = 'offers'

const p = (name: string) => ROUTE_NAME + name

export const CREATE = p(':create')
export const DETAIL = p(':detail')
export const UPDATE = p(':update')

const {
  CHANGE,
} = OFFERS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(CREATE_OFFER, CreateOffersView, CREATE, {
      props: true,
      meta: {
        title: $t('Додавання пропозиції на продаж газу'),
        ...accessPermission(CHANGE),
      },
    }),
    useRoute(DETAIL_OFFER, CreateOffersView, DETAIL, {
      props: true,
      meta: {
        title: $t('Деталі пропозиції'),
        ...accessPermission(CHANGE),
      },
    }),
    useRoute(UPDATE_OFFER, CreateOffersView, UPDATE, {
      props: true,
      meta: {
        title: $t('Редагування пропозиції'),
        ...accessPermission(CHANGE),
      },
    }),
  ]
}
