import { App, defineAsyncComponent } from 'vue'

const FormViewsController = defineAsyncComponent(() => import('./FormViewsController.vue'))
// const RegistrationView = defineAsyncComponent(() => import('./RegistrationView.vue'))
const RegistrationView = defineAsyncComponent(() => import('@components/Forms/Registration/RegistrationForm.vue'))

const register = (app: App<Element>): void => {
  app
    .component('FormViewsController', FormViewsController)
    .component('RegistrationView', RegistrationView)
  }

export default {
  register,
}
