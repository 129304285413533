import { OFFERS_CATALOG } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { OFFERS } from '@md/users/index'

const BusinessOffersCatalog = () => import('@components/Profiles/Common/OffersCatalog/View.vue')

export const OFFERS_ROUTE = 'offers'

const {
  VIEW_DIVIDED,
} = OFFERS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(OFFERS_CATALOG, BusinessOffersCatalog, OFFERS_ROUTE, {
      props: true,
      meta: {
        title: $t('Пропозиції продажу газу'),
        ...accessPermission(VIEW_DIVIDED),
      },
    }),
  ]
}
