import { SELLER_DEALS } from '@/router/Seller/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DEALS } from '@md/users/index'

const DealsCatalog = () => import('@components/Profiles/Seller/Deals/View.vue')

export const SELLER_DEALS_ROUTE = 'seller-deals'
const {
  VIEW_SELLER,
} = DEALS

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(SELLER_DEALS, DealsCatalog, SELLER_DEALS_ROUTE, {
      props: true,
      meta: {
        title: $t('Угоди'),
        ...accessPermission(VIEW_SELLER),
      },
    }),
  ]
}
