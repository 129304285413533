<template>
<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4523_7001)">
<path d="M10.0007 1.62109H6.00065C5.63246 1.62109 5.33398 1.91957 5.33398 2.28776V3.62109C5.33398 3.98928 5.63246 4.28776 6.00065 4.28776H10.0007C10.3688 4.28776 10.6673 3.98928 10.6673 3.62109V2.28776C10.6673 1.91957 10.3688 1.62109 10.0007 1.62109Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.666 2.95703H11.9993C12.353 2.95703 12.6921 3.09751 12.9422 3.34756C13.1922 3.5976 13.3327 3.93674 13.3327 4.29036V13.6237C13.3327 13.9773 13.1922 14.3165 12.9422 14.5665C12.6921 14.8166 12.353 14.957 11.9993 14.957H3.99935C3.64573 14.957 3.30659 14.8166 3.05654 14.5665C2.80649 14.3165 2.66602 13.9773 2.66602 13.6237V4.29036C2.66602 3.93674 2.80649 3.5976 3.05654 3.34756C3.30659 3.09751 3.64573 2.95703 3.99935 2.95703H5.33268" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6 9.6224L7.33333 10.9557L10 8.28906" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_4523_7001">
<rect width="16" height="16" fill="white" transform="translate(0 0.289062)"/>
</clipPath>
</defs>
</svg>
</template>
