import { App } from 'vue'

import OffersCatalog from './View.vue'

const register = (app: App<Element>): void => {
  app
    .component('OffersCatalog', OffersCatalog)
  }

export default {
  register,
}
