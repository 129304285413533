import Shepherd from 'shepherd.js'
import { useShepherd } from 'vue-shepherd'

function showProgress(this: any) {
  const currentStepElement: HTMLElement = this.currentStep.el
  const header = currentStepElement.querySelector('.shepherd-header')!
  const progress = document.createElement('span')
  progress.innerText = `${this.steps.indexOf(this.currentStep) + 1}/${
    this.steps.length
  }`
  header.insertBefore(
    progress,
    currentStepElement.querySelector('.shepherd-cancel-icon')
  )
}

export const OffersListSteps: any = (tour: Shepherd.Tour) => [
  {
    attachTo: { element: '#tour-header-notification', on: 'bottom' },
    title: 'Сповіщення',
    text: 'Натиснувши на цей елемент ви перейдете на сторінку “Сповіщення” з переліком отриманих Вами сповіщень. Мітка на кнопці позначає кількість непрочитаних сповіщень.',
    when: {
      show: showProgress.bind(tour),
    },
    modalOverlayOpeningPadding: 12,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 22],
          },
        },
      ],
    },
    buttons: [
      {
        action: function (this: Shepherd.Tour): void {
          return this.cancel()
        },
        text: 'Exit',
        classes: 'shepherd-btn shepherd-btn--variant_exit',
      },
      {
        action: function (this: Shepherd.Tour): void {
          return this.next()
        },
        text: 'Next',
        classes: 'shepherd-btn shepherd-btn--variant_next',
      },
    ],
  },
  {
    attachTo: { element: '#tour-cab-user-bar', on: 'bottom' },
    title: 'Особистий кабінет',
    text: `<div>
      <ol>
        <li>
          Відображається логотип, назва компанії та Ваше ім’я.
        </li>
        <li>
          Після кліку на стрілку відкриється меню з переліком Ваших компаній та іконками, які позначають статус їх верифікації;
          <br>
          <ul>
            <li>
            <svg style="vertical-align: middle;" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-icon"><g><path d="M8.93815 14.6673C12.62 14.6673 15.6048 11.6825 15.6048 8.00065C15.6048 4.31875 12.62 1.33398 8.93815 1.33398C5.25625 1.33398 2.27148 4.31875 2.27148 8.00065C2.27148 11.6825 5.25625 14.6673 8.93815 14.6673Z" stroke="#082964" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.9375 6L6.9375 10" stroke="#082964" fill="#082964" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.9375 6L10.9375 10" stroke="#082964" fill="#082964" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g></svg>
             — Ви не завершили верифікацію, або статус “Не верифіковано”;
            </li>
            <li>
            <svg style="vertical-align: middle;" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-icon"><g clip-path="url(#clip0_1575_19733)"><path d="M8.7526 15.6224C12.4346 15.6224 15.4193 12.6377 15.4193 8.95573C15.4193 5.27373 12.4346 2.28906 8.7526 2.28906C5.0706 2.28906 2.08594 5.27373 2.08594 8.95573C2.08594 12.6377 5.0706 15.6224 8.7526 15.6224Z" stroke="#6ABF4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M6.75195 8.95443L8.08529 10.2878L10.752 7.62109" stroke="#6ABF4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_1575_19733"><rect width="16" height="16" fill="white" transform="translate(0.751953 0.955078)"></rect></clipPath></defs></svg>
             — статус “Верифіковано”;
            </li>
            <li>
            <svg style="vertical-align: middle;" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="svg-icon"><g clip-path="url(#clip0_1575_19744)"><path d="M8.93815 15.2826C12.62 15.2826 15.6048 12.2978 15.6048 8.61588C15.6048 4.93399 12.62 1.94922 8.93815 1.94922C5.25625 1.94922 2.27148 4.93399 2.27148 8.61588C2.27148 12.2978 5.25625 15.2826 8.93815 15.2826Z" stroke="#DA224A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.9375 11.2819V8.61523" stroke="#DA224A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.9375 5.94922H8.94417" stroke="#DA224A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></g><defs><clipPath id="clip0_1575_19744"><rect width="16" height="16" fill="white" transform="translate(0.9375 0.615234)"></rect></clipPath></defs></svg>
             — статуси “Потребує уточнення” або “На повторній верифікації”.                
            </li>
          </ul>
          <p>
            Клікнувши на назву компанії зі статусом “Верифіковано” ви оберете компанію як основну і далі працюватимете з нею.
            <br>
            Клік на назву компанії з іншими статусами перенесе Вас на її сторінку.              
          </p>
        </li>
        <li>
          Кнопка “Вийти” — вихід з вашого акаунта.
        </li>
      </ol>
    </div>`,
    modalOverlayOpeningPadding: 12,
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 22],
          },
        },
      ],
    },
  },
  {
    attachTo: { element: '#tour-sidebar-icon', on: 'right' },
    title: 'Відображення меню',
    text: 'Для зручного перегляду даних (наприклад, пропозиції продажу газу), Ви можете згорнути або розгорнути бокове меню. Для цього натисніть на цю іконку.',
    when: {
      show: showProgress.bind(tour),
    },
    modalOverlayOpeningPadding: 10,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  },
  {
    attachTo: { element: '#tour-sidebar', on: 'right' },
    title: 'Бокове меню',
    text: 'Бокове меню являє собою перелік посилань на всі необхідні сторінки Платформи, а також доступ до сторінки зі всіма Вашими компаніями, налаштуванням профілю або виходу з акаунта.',
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  },
  {
    attachTo: { element: '#tour-price-analytics', on: 'bottom' },
    title: 'Аналітика цін на газ',
    text: 'Ця секція представляє найактуальніші дані котирувань газу.',
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  },
  {
    attachTo: { element: '#tour-cabinet-search', on: 'bottom' },
    title: 'Пошук',
    text: 'Якщо Ви знаєте точний ID необхідної пропозиції газу, то введіть його в цьому полі й ми знайдемо цю пропозицію для Вас.',
    modalOverlayOpeningPadding: 8,
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  },
  {
    attachTo: { element: '#tour-cabinet-create-offer', on: 'left' },
    title: 'Створити пропозицію',
    text: 'Натиснувши цю кнопку Ви перейдете на сторінку “Створення пропозиції продажу газу”, де зможете оформити пропозицію, яка потрапить в каталог всіх пропозицій.',
    modalOverlayOpeningPadding: 8,
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  },
  {
    attachTo: {
      element: '#tour-content-view-table-row-header',
      on: 'bottom',
    },
    title: 'Сортування пропозицій',
    text: `Щоб підібрати найкращу пропозицію продажу газу Ви можете скористатись сортуванням за ціною необхідних умов.
    Після кліку на стрілку “вверх” пропозиції відсортуються за ціною від меншої до найбільшої.
    Після кліку на стрілку “вниз” пропозиції відсортуються за ціною від найбільшої до найменшої.`,
    modalOverlayOpeningPadding: 5,
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  },
  {
    attachTo: { element: '#tour-content-view-table', on: 'top' },
    title: 'Пропозиції продажу газу',
    text: `Тут ви бачите перелік всіх активних пропозицій продажу газу. Будь-яка з них доступна для купівлі.
    Також, Ви можете запропонувати продавцю торг щодо ціни, або завантажити PDF-файл комерційної пропозиції, в разі, якщо Вам необхідно порадитись з колегами щодо прийняття рішення.`,
    modalOverlayOpeningPadding: 5,
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  },
  {
    attachTo: { element: '#tour-cabinet-catalog-pagination', on: 'top' },
    title: 'Пагінація',
    text: `Для зручності користування ви можете обрати яку кількість пропозицій хочете бачити на одній сторінці, це може бути число від 10 і до 100.
      Також тут відображається кількість активних пропозицій продажу газу.`,
    modalOverlayOpeningPadding: 12,
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },
  },
  {
    attachTo: { element: '#tour-filters-view', on: 'bottom' },
    title: 'Фільтрація пропозицій',
    text: `<div>
    <p>
      В разі, якщо Вам необхідно підібрати пропозицію під конкретні характеристики, Ви можете скористатись фільтрацією, а саме:
    </p>
    <ul>
      <li>
        визначити необхідний період поставки газу;
      </li>
      <li>
        вказати яка кількість газу Вам необхідна;
      </li>
      <li>
        відібрати лише необхідні умови пропозицій;
      </li>
      <li>
        встановити ціновий діапазон для пропозицій.
      </li>
    </ul>
  </div>`,
    when: {
      show: showProgress.bind(tour),
    },
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
      ],
    },

    buttons: [
      {
        action: function (this: Shepherd.Tour): void {
          return this.cancel()
        },
        text: 'Exit',
        classes: 'shepherd-btn shepherd-btn--variant_exit',
      },
      {
        action: function (this: Shepherd.Tour): void {
          return this.back()
        },
        text: 'Previous',
        classes: 'shepherd-btn shepherd-btn--variant_prev',
      },
      {
        action: function (this: Shepherd.Tour): void {
          return this.next()
        },
        text: 'Done',
        classes: 'shepherd-btn shepherd-btn--variant_next',
      },
    ],
  },
]

export const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    scrollTo: true,
    buttons: [
      {
        action: function (this: any): void {
          return this.cancel()
        },
        text: 'Exit',
        classes: 'shepherd-btn shepherd-btn--variant_exit',
      },
      {
        action: function (this: any): void {
          return this.back()
        },
        text: 'Previous',
        classes: 'shepherd-btn shepherd-btn--variant_prev',
      },
      {
        action: function (this: any): void {
          // console.log(this)
          return this.next()
        },
        text: 'Next',
        classes: 'shepherd-btn shepherd-btn--variant_next',
      },
    ],
  },
})
