import { App, defineAsyncComponent } from 'vue'

const UiLinechart = defineAsyncComponent(() => import('./Line.vue'))

const register = (app: App<Element>): void => {
  app
    .component('UiLinechart', UiLinechart)
}

export default {
  register,
}
