<template>
<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1575_19744)">
<path d="M8.93815 15.2826C12.62 15.2826 15.6048 12.2978 15.6048 8.61588C15.6048 4.93399 12.62 1.94922 8.93815 1.94922C5.25625 1.94922 2.27148 4.93399 2.27148 8.61588C2.27148 12.2978 5.25625 15.2826 8.93815 15.2826Z" stroke="#56D0E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.9375 11.2819V8.61523" stroke="#56D0E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.9375 5.94922H8.94417" stroke="#56D0E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1575_19744">
<rect width="16" height="16" fill="white" transform="translate(0.9375 0.615234)"/>
</clipPath>
</defs>
</svg>
</template>
