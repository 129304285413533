import { prefixLanguage } from '@sub/src/utils/urls'
import { BASE_URL } from '@/router/consts'

const p = (postfix: string) => prefixLanguage(`${BASE_URL}${postfix}/`)

export const OWNED_OFFERS_CATALOG = p(':id/offers/owned')

export const CREATE_OFFER = p(':id/offers/create')
export const DETAIL_OFFER = p(':id/offers/detail/:offer')
export const UPDATE_OFFER = p(':id/offers/update/:offer')

export const ORDERS_CATALOG = p(':id/orders')
