export const PLAT4MA_BUSINESS = 'business'
export const PLAT4MA_TRADER = 'trader'

export const COMPANY_DATE = 'company_data'
export const ACTIVE_COMPANY = 'ACTIVE_COMPANY'
export const PLAT4MA_HAS_COMPANY = 'PLAT4MA_HAS_COMPANY_DATA'
export const STEP_TWO_CASHED = 'step_two_cashed'
export const NOVAPOSHTA_INFO_CASHED = 'novaposhta_info_cashed'
export const PROFILE_SIDEBAR_OPEN = 'PROFILE_SIDEBAR_OPEN'
export const COMPANIES_VIEWS_COOKIE = 'COMPANIES_VIEWS'
export const BLOCKED_COOKIE = 'READING_ABOUT_BLOCKED'

export const DEFAULT_IMG = '/static/app/company_default.png'
export const DEFAULT_COMPANY_IMG = '/static/app/heroes/main_fire.svg'
export const DEFAULT_P4 = '/static/app/p4.png'
export const DEFAULT_AVA = '/static/app/bargain_def.png'
export const DEFAULT_P4_GREY = '/static/app/p4_grey.png'
export const CURRENCY = 'грн.'

export const VIEWER_PATH = '/static/app/lib/pdfjs-3.4.120-dist/web/viewer.html'

export const MINUS = '-'
export const PLUS = '+'

export const BLOG_BG_W = '/static/app/bg_blog.webp'
export const BLOG_BG = '/static/app/bg_blog.png'

export const GAS_PROD_URL = 'p4.com.ua'
export const GAS_TEST_URL = 'p4.webcase-dev.com'

