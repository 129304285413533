import { App } from 'vue'

import Lib from './lib'

const install = (app: App<Element>): void => {
  Lib.install(app)
}

export default {
  install,
}
