import { parseTemplate } from 'url-template'

type R = Record<string, any>

type Params = {
  filters: R,
  limit?: number,
  offset?: number,
}

interface Option {
  slug?: string;
  id?: string;
}

const filterUrl = parseTemplate('/{;filters*}/')
const zero = 0

export function friendlyUrlParser(): R {
  let path = window.location.pathname
  const zero = 0
  const parsedUrl: R = {}
  if (path.indexOf('filters') > zero) {
    path = path.split('/page')[0]
    const hashes = path.split('filters/')[1].split(';')
    hashes.map(hash => {
      const [key, val] = hash.split('=')

      parsedUrl[key] = []
      if (val.indexOf(',') > zero) {
        const values = val.split(',')
        values.forEach(v => {
          if (v.indexOf('/') > zero) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
          } else {
            parsedUrl[key].push(decodeURIComponent(v))
          }
        })
      } else if (val.indexOf('/') > zero) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
      } else {
        parsedUrl[key].push(decodeURIComponent(val))
      }
    })
  }
  return parsedUrl
}

export function reduceFiltersData(data: R): R {
  const reducedData: R = Object.keys(data).reduce((acc, key) => {
    if (data[key]) {
      if (Array.isArray(data[key]) &&
        data[key].length) {
        acc[key] = data[key].map((option: Option) => {
          if (option.slug) {
            return option.slug
          }
          if (option.id) {
            return option.id
          }
          return option
        })
      } else if ('string' === typeof data[key]) {
        acc[key] = data[key]
      } else {
        acc[key] = data[key]?.slug
        if (data[key].id) {
          acc[key] = data[key].id
        }
      }
    }
    return acc
  }, {} as R)
  return reducedData
}

export const checkFilter = (data: R): boolean => {
  return Object.keys(data).every(key => !data[key])
}

export function createFriendlyUrl(data: R): string {
  let url: string = filterUrl.expand({ filters: data })
  url = url.replace(/;/, '')
    .replace(/%2C/g, ',')
    .replace(/\//, '')
  if ('/' === url) {
    url = ''
  }
  return url
}

export function prettifyUrl(params: Params, base: string): void {
  // console.log(params, 'params')
  const keys = Object.keys(params.filters)

  for (let x = 0; x <= keys.length; x++) {
    if (params.filters[keys[x]] !== undefined) {
      if (zero === params.filters[keys[x]].length) {
        delete params.filters[keys[x]]
      }
    }
  }
  let url = filterUrl.expand(params)
  url = url.replace(/;/, '')
  url = url.replace(/%2C/g, ',')
  const length = 2
  let fullUrl
  const shift = 1
  let page = 0
  if (params.offset && params.limit) {
    page = Math
      .floor(params.offset / params.limit) + shift
  }

  if (url.length > length) {
    fullUrl = `/${base}/filters${url}`
  } else {
    fullUrl = `/${base}/`
  }

  if (page > shift) {
    fullUrl = `${fullUrl}page/${page}/`
  }

  window.history.pushState({ url: fullUrl }, '', `${fullUrl}${window.location.search}`)
}
