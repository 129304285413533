/* eslint-disable prefer-rest-params */

export function collectRoutePermissionCheckers(route) {
  return route.matched.reduce((acc, r) => {
    const checker = r.meta.accessConfig && r.meta.accessConfig.checker;

    if (checker) {
      acc.push([checker, r]);
    }
    return acc;
  }, []);
}

export function routeProhibitionResolver(route) {
  const { resolver, next, redirect = false } = route.meta.accessConfig;
  if (resolver) {
    return resolver(route) || false;
  }
  return next || redirect || false;
}

export function onHasRouteAccess(r, vm, parameters = {}) {
  const checkers = collectRoutePermissionCheckers(r);

  if (!(checkers && checkers.length)) {
    return Promise.resolve(true);
  }
  const executors = checkers.map(
    ([checker, route]) => checker
      .onHasAccess(vm, { route, ...parameters })
      .catch(() => { throw route; })
      .then(() => true)
  );
  return Promise.all(executors);
}

export function resolveRouteNext(r, vm, parameters = {}) {
  return onHasRouteAccess(r, vm, parameters)
    .catch(routeProhibitionResolver);
}

export const accessGuard = (parameters = {}) => (to, from, next) => (
  resolveRouteNext(to, null, parameters).then(result => {
    if (Array.isArray(result)) {
      return next(result.every(Boolean));
    }
    return next(result);
  })
);

export const accessConfig = (checker, redirect = false, options = {}) => ({
  checker, redirect, ...options,
});

export function accessMeta() {
  return { accessConfig: accessConfig.apply(this, arguments) };
}
