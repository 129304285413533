import { DOCUMENTS_DETAIL, CONTRACT_DETAIL } from '@/router/Common/consts'
import { STAFF_DOCUMENTS_DETAIL } from '@/router/Staff/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

import { accessPermission } from '@md/users/permissions'
import { DOCS_DEAL } from '@md/users/index'

const DocumentsCatalog = () => import('@components/Profiles/Common/Documents/Details/View.vue')

export const DOCUMENTS_DETAILS_ROUTE = 'documents-details'
export const CONTRACT_DETAILS_ROUTE = 'contract-details'
export const STAFF_DOCUMENTS_DETAILS_ROUTE = 'staf-documents-details'

const {
  VIEW,
} = DOCS_DEAL

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(DOCUMENTS_DETAIL, DocumentsCatalog, DOCUMENTS_DETAILS_ROUTE, {
      props: true,
      meta: {
        title: $t('Документ'),
        ...accessPermission(VIEW),
      },
    }),
    useRoute(CONTRACT_DETAIL, DocumentsCatalog, CONTRACT_DETAILS_ROUTE, {
      props: true,
      meta: {
        title: $t('Документ'),
      },
    }),
    useRoute(STAFF_DOCUMENTS_DETAIL, DocumentsCatalog, STAFF_DOCUMENTS_DETAILS_ROUTE, {
      props: true,
      meta: {
        title: $t('Документ'),
      },
    }),
  ]
}
