import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import './styles/index.sass'
import 'tippy.js/dist/tippy.css'
import 'shepherd.js/dist/css/shepherd.css';

import { scroller } from '@sub/src/utils/scroller'
import WEBCASEConsole from '@sub/src/utils/webcase-console'

const app = createApplication({
  createApp, createRouter, createWebHistory, createPinia,
})

WEBCASEConsole()
// for scroll in firefox
const userAgent = navigator.userAgent.toLowerCase()
const mozila = /firefox/.test(userAgent)
if (mozila) {
  window.addEventListener('hashchange', () => scroller())
  const { body } = document
  body.setAttribute('data-mz', 'true')
}
// for scroll at init in iOS
document.addEventListener("DOMContentLoaded", () => scroller())
