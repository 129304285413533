import { BARGAINING_CATALOG } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

// import { accessPermission } from '@md/users/permissions'
// import { } from '@md/users/index'

const BargainingCatalog = () => import('@components/Profiles/Common/Bargaining/View.vue')

export const BARGAINING_ROUTE = 'bargaining'
// const {
//   VIEW,
// } = 

export function createRoutes(options:optionsT): any {
  const { app } = options
  const { $t } = app.config.globalProperties
  return [
    useRoute(BARGAINING_CATALOG, BargainingCatalog, BARGAINING_ROUTE, {
      props: true,
      meta: {
        title: $t('Торги'),
        // ...accessPermission(VIEW),
      },
    }),
  ]
}
