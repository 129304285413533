import { prefixLanguage } from '@sub/src/utils/urls'
import { BASE_URL } from '@/router/consts'

const p = (postfix: string) => prefixLanguage(`${BASE_URL}${postfix}/`)

// OFFERS
export const SELLER_OFFERS_CATALOG = p('seller/offers')
export const SELLER_DETAIL_OFFER = p('seller/offers/details/:id/:offer')

// ORDERS
export const SELLER_ORDERS_CATALOG = p('seller/orders')

// DEALS
export const SELLER_DEALS = p('seller/deals')
export const SELLER_DEAL_DETAILS = p('seller/deal/details/:id/:deal')

//  BARGAINING
export const SELLER_BARGAINING_CATALOG = p('seller/bargaining')

// COMPANIES
export const SELLER_COMPANIES = p('seller/companies')
export const SELLER_COMPANY = p('seller/company/:id')

// USERS
export const SELLER_USERS = p('seller/users')
