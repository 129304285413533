import { Ref, ref, unref } from 'vue'
import isShallowEqual from '@wordpress/is-shallow-equal'

import {
  useGenerator, GeneratorConfig, FilterAvailabilityDefinition, FilterDefinition,
} from './generator'
import { useLoadable } from './loadingState'

type ControllerConfig<T = Object> = Omit<GeneratorConfig, 'available'> & {
  available: (value: T) => Promise<FilterAvailabilityDefinition[]>
}

const toPrevious = (value: any) => JSON.parse(JSON.stringify(value))

export function useController<T = Object>(
  config: ControllerConfig<T>,
  value: Ref<T>,
) {
  const available = useLoadable(config.available)
  const oldValue = ref(toPrevious(unref(value)))
  const onApply = (val: T) => {
    const parent = unref(config.onApply)

    if (parent) {
      parent(val)
    }

    const current = toPrevious(val)

    if (isShallowEqual(current, oldValue.value)) return

    oldValue.value = current
    available.load(val)
  }
  const { keyGetter, typeGetter, nodes } = useGenerator(
    { ...config, onApply, available: available.result }, value,
  )
  available.load(unref(value))

  return {
    keyGetter,
    typeGetter,
    value,
    nodes,
    loading: available.loading,
    filters: config.filters,
    available: available.result,
    onApply,
  }
}

export type Controller = ReturnType<typeof useController>

export type FiltersControllerConfig<T = Object> = Omit<ControllerConfig<T>, 'filters'> & {
  filters: () => Promise<FilterDefinition[]>
}

export function useFiltersController<T = Object>(
  config: FiltersControllerConfig<T>,
  value: Ref<T>,
) {
  const filters = useLoadable(config.filters)
  filters.load()

  return useController({ ...config, filters: filters.result, }, value)
}

export type FiltersController = ReturnType<typeof useFiltersController>
