import { AFTER_PAYMENT } from '@/router/Common/consts'
import useRoute from '@/router/utils'
import { optionsT } from '@/router'

const AfterPayment = () => import('@components/Profiles/Common/Documents/Payments/AfterPayment.vue')

export const AFTER_PAYMENT_ROUTE = 'after-payment'

export function createRoutes(options:optionsT): any {
  return [
    useRoute(AFTER_PAYMENT, AfterPayment, AFTER_PAYMENT_ROUTE, {
      props: true,
    }),
  ]
}
